// main
import React, { useEffect, useState } from 'react';

// date-fns
import { format, set, differenceInMinutes } from 'date-fns';

import classNames from 'classnames';

// api request
import axios from 'axios';

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';
// import { impiantoInitialValues, impiantoValidationSchema } from '../../Impianto/formValues';

// other
import { toast } from 'react-toastify';
import { PlusIcon, XIcon, CheckIcon } from '@heroicons/react/outline';

import Input from '../../../components/forms/input';
import TextArea from '../../../components/forms/textarea';
import Select from '../../../components/forms/select';

// Component
export default function ModalNuovoAppuntamento(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((isOpen) => !isOpen);
  const { token } = useAuth();

  const { showLoading, hideLoading } = useLoading();

  const { values, errors, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
    initialValues: {
      title: '',
      startDate: new Date(props.date).toISOString(),
      duration: 60,
      resourceId: props.resourceId,
      notes: '',
      type: '',
    },
    // validationSchema: {},
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: async (values) => {
      showLoading();
      try {
        await axios.post(`${process.env.REACT_APP_API_HOST}/api/appuntamenti`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        props.refetch();
        toast.success('Appuntamento creato');
        toggleModal();
      } catch (e) {
        toast.error('Appuntamento non creato');
        if (props.resourceId === '') toast.error('Seleziona un tecnico');
      } finally {
        hideLoading();
        props.refetch();
      }
    },
  });

  useEffect(() => {
    setFieldValue('resourceId', props.resourceId);
  }, [props.resourceId]);
  const handleDateChange = (e) => {
    const [year, month, date] = e.target.value.split('-');

    const pippo = set(new Date(values.startDate), {
      year,
      month: Number(month) - 1,
      date,
    });

    setFieldValue('startDate', pippo);
  };

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(':');

    const pippo = set(new Date(values.startDate), {
      hours,
      minutes,
    });

    setFieldValue('startDate', pippo);
  };

  return isOpen ? (
    <div className="fixed top-0 left-0 z-50 h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25">
      <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
        <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
          <h1 className="font-bold text-2xl">Fissa nuovo appuntamento</h1>
          <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={toggleModal} />
        </div>

        <form onSubmit={handleSubmit} className="bg-white overflow-y-scroll flex-1">
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Evento</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    label=""
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title}
                  />
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Note</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <TextArea
                    label=""
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    error={errors.notes}
                  />
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Data</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    label=""
                    name="startDate"
                    value={format(new Date(values.startDate || null), 'yyyy-MM-dd')}
                    onChange={handleDateChange}
                    error={errors.startDate}
                    type="date"
                  />
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Ora</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    label=""
                    name="startDate"
                    value={format(new Date(values.startDate || null), 'kk:mm')}
                    onChange={handleTimeChange}
                    error={errors.startDate}
                    type="time"
                  />
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Durata (minuti)</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    label=""
                    name="duration"
                    value={values.duration}
                    onChange={handleChange}
                    error={errors.duration}
                    type="number"
                  />
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Tipo</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Select
                    label=""
                    options={[
                      { id: 'GUASTO', name: 'GUASTO' },
                      { id: 'MANUTENZIONE', name: 'MANUTENZIONE' },
                      { id: 'COLLAUDO', name: 'COLLAUDO' },
                      { id: 'ALTRO', name: 'ALTRO' },
                    ]}
                    colspan={1}
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    error={errors.type}
                  />
                </dd>
              </div>

              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  <button
                    onClick={
                      Object.keys(errors).length > 0
                        ? () => toast.error('Controlla i campi')
                        : handleSubmit
                    }
                    className="inline-flex justify-center items-center px-6 h-14 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center md:w-auto">
                    <CheckIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
                    Salva
                  </button>
                </dt>
              </div>
            </dl>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <form className="flex w-2/3 p-8" onSubmit={() => {}}>
      <input
        type="text"
        name="title"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="Nuovo appuntamento"
        value={values.title}
        onChange={handleChange}
      />

      <button
        onClick={toggleModal}
        type="button"
        className="ml-4 p-2 h-full rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </form>
  );
}
