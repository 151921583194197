import * as Yup from 'yup';

export default Yup.object().shape({
  ragione_sociale: Yup.string().required('Ragione sociale richiesta'),
  partita_iva: Yup.string().required('Partita IVA richiesta'),
  responsabile: Yup.object({
    nome: Yup.string().required('Nome richiesto'),
    cognome: Yup.string().required('Cognome richiesto'),
    codice_fiscale: Yup.string().required('Codice fiscale richiesto'),
  }),
});
