import React from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../Context/AuthContext';

const AdminRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  console.log(useParams());
  const { isLogged, user } = useAuth();
  return (
    <Route
      render={(props) => {
        if (isLogged) {
          if (user.isActive) {
            if (!user.azienda && pathname !== '/onboarding') return history.push('/onboarding');
            if (user.azienda && pathname === '/onboarding') return history.push('/');
            return <Component {...props} />;
          } else history.push('/logout');

          return toast.info('Controlla la tua mail e attiva il tuo account');
        } else history.push('/');
      }}
      {...rest}
    />
  );
};

export default AdminRoute;
