import React, { useContext, createContext, useState, useEffect } from 'react';

import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const override = css`
    position: fixed;
    margin: 0 auto;
    width: 100vw;
  `;

  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      <BarLoader loading={isLoading} css={override} color="#35A7FF" />
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext);
