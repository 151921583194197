import React from 'react';

function FilterInput({ label = 'Label', name, icon: Icon, ...props }) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-black">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-black" />
          </div>
        )}
        <input
          type="text"
          id={name}
          name={name}
          className="h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          {...props}
        />
      </div>
    </div>
  );
}

export default FilterInput;
