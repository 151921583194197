import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

import { startOfWeek, subWeeks } from 'date-fns';
import { addWeeks, endOfWeek } from 'date-fns/esm';

const Header = (props) => {
  const onNextMonthClick = () => {
    props.onChangeDate(addWeeks(endOfWeek(props.selectedDate), 1));
  };

  const onPreviousMonthClick = () => {
    props.onChangeDate(subWeeks(startOfWeek(props.selectedDate), 1));
  };

  const onTodayClick = () => {
    props.onChangeDate(new Date(new Date().toUTCString()));
  };

  return (
    <div className="flex justify-between p-6">
      <h1 className="font-bold text-xl">
        {props.selectedDate.toLocaleString('default', { month: 'long' }).toUpperCase()}{' '}
        {props.selectedDate.getFullYear()}
      </h1>

      <div className="flex">
        <button
          onClick={() => {
            props.onChangeView('month');
          }}
          type="button"
          className="mr-6 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          Mensile
        </button>
        <div className="gap-2 flex items-center">
          <button onClick={onPreviousMonthClick} type="button" className="w-10 p-2">
            <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          </button>
          <button onClick={onTodayClick} type="button">
            Oggi
          </button>
          <button onClick={onNextMonthClick} type="button">
            <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
