import styled from 'styled-components';

export const CloseModal = styled.button`
  width: 15px;
  border: none;
  outline: none;
  background: none;

  cursor: pointer;
  font-weight: 100;

  &:hover {
    font-weight: 700;
  }
`;

export const ModalContainer = styled.form`
  background-color: white;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  border-radius: 1rem;
  margin: 0 auto;
  flex: 1;
  max-width: 1024px;
  height: 90vh;
  overflow-y: scroll;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
`;
export const ModalBody = styled.form`
  flex: 1;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;
export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;

  padding-top: 0.25rem;
  margin-top: 1rem;
`;

export const modalStyle = {
  overlay: {},
  content: {
    top: '5rem',
    bottom: '5rem',
    left: '5rem',
    right: '5rem',
    margin: '0 auto',
    minWidth: '750px',
    width: 'min-content',
    maxWidth: '75%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};
