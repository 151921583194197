// main
import React, { useState } from 'react';
import { useFormik } from 'formik';
import Modal from 'react-modal';

import {
  PhoneIcon,
  XIcon,
  UserIcon,
  SearchIcon,
  MapIcon,
  LocationMarkerIcon,
  RefreshIcon,
  CheckIcon,
} from '@heroicons/react/outline';

import FilterInput from '../../../components/forms/filterinput';

function FiltersModal({ query = {}, setQuery, resetFilters }) {
  const [isOpen, setIsOpen] = useState(false);
  const hideModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: query,
    onSubmit(values) {
      setQuery({ ...values, offset: 0 });
      hideModal();
    },
  });

  return (
    <>
      <button
        onClick={showModal}
        className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <SearchIcon className="h-6 w-6" />
      </button>

      <Modal
        isOpen={isOpen}
        className="absolute h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25">
        <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
          <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
            <h1 className="font-bold text-2xl">Filtri</h1>
            <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={hideModal} />
          </div>

          <form className="p-8 space-y-4 items-end" onSubmit={handleSubmit}>
            <FilterInput
              label="Cliente"
              name="cliente"
              placeholder="Cliente..."
              value={values.cliente}
              onChange={handleChange}
              icon={UserIcon}
            />

            <FilterInput
              label="Telefono"
              name="telefono"
              placeholder="Telefono..."
              value={values.telefono}
              onChange={handleChange}
              icon={PhoneIcon}
            />

            <FilterInput
              label="Comune"
              name="comune"
              placeholder="Comune..."
              value={values.comune}
              onChange={handleChange}
              icon={MapIcon}
            />

            <FilterInput
              label="Indirizzo"
              name="indirizzo"
              placeholder="Indirizzo..."
              value={values.indirizzo}
              onChange={handleChange}
              icon={LocationMarkerIcon}
            />

            <div className="flex justify-between mt-10">
              <button
                type="button"
                onClick={() => {
                  resetFilters();
                  resetForm();
                  hideModal();
                }}
                className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <RefreshIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Ripristina
              </button>

              <button
                type="submit"
                className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Applica
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

Modal.setAppElement('#root');

export default FiltersModal;
