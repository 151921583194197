// main
import React, { useEffect, useState } from 'react';

import { XIcon, TrashIcon, EyeIcon, PlusIcon } from '@heroicons/react/outline';
import { addMinutes, format } from 'date-fns';
import ModalAppuntamento from './ModalAppuntamento';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../Context/AuthContext';

import ModalNuovoAppuntamento from './ModalNuovoAppuntamento';
import classNames from 'classnames';

// Component
export default function ModalGiorno(props) {
  const { token } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen((isOpen) => !isOpen);

  async function onDeleteEvent({ _id }) {
    const confirmed = window.confirm('Sei sicuro?');

    if (confirmed) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_HOST}/api/appuntamenti/${_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        props.refetch();

        toast.success('Appuntamento eliminato');
      } catch (error) {
        console.log(error);
        toast.error("C'è stato un errore");
      }
    }
  }

  return (
    <>
      <div onClick={toggleModal}>{props.children}</div>

      {isOpen ? (
        <div
          className="fixed top-0 left-0 z-50 h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25"
          style={{
            zIndex: 9999,
          }}>
          <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
            <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
              <h1 className="font-bold text-2xl">
                {format(new Date(props.day.date), 'dd/MM/yyyy')}
              </h1>
              <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={toggleModal} />
            </div>

            <div className="flex justify-center">
              <ModalNuovoAppuntamento
                refetch={props.refetch}
                resourceId={props.resourceId}
                date={props.day.date}>
                {/* <div className="flex w-2/3 p-8">
                  <input
                    type="text"
                    name="title"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Nuovo appuntamento"
                    value={title}
                    onChange={({ target }) => setTitle(target.value)}
                  />

                  <button
                    type="button"
                    className="ml-4 p-2 h-full rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div> */}
              </ModalNuovoAppuntamento>
            </div>

            <div className="overflow-y-scroll flex justify-center">
              <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
                {props.day.events.map((event, idx) => (
                  <li key={event._id} className="flex w-full p-8">
                    <div className="flex-1">
                      <div className="flex">
                        <time
                          dateTime={format(event.startDate, 'yyyy-MM-dd')}
                          className="text-base w-28 flex-none">
                          {format(event.startDate, 'HH:mm')}
                        </time>
                        <p
                          className={classNames(
                            'text-xl mt-2 flex-auto font-medium text-gray-900 sm:mt-0',
                            {
                              'text-green-500': event.type === 'MANUTENZIONE',
                              'text-red-500': event.type === 'GUASTO',
                              'text-blue-500': event.type === 'ALTRO',
                              'text-yellow-500': event.type === 'COLLAUDO',
                            },
                          )}>
                          {event.title}
                        </p>
                        <p className="text-base flex-none sm:ml-6">
                          <time dateTime="2022-01-13T14:30">
                            {' '}
                            {format(event.startDate, 'HH:mm')}
                          </time>{' '}
                          -{' '}
                          <time dateTime="2022-01-13T16:30">
                            {format(addMinutes(event.startDate, event.duration), 'HH:mm')}
                          </time>
                        </p>
                      </div>

                      <p className="text-base ml-28 mt-2 p-2 rounded bg-gray-100 text-gray-900">
                        {event.notes}
                      </p>
                    </div>

                    <div className="flex justify-center items-start ml-8 gap-4">
                      <ModalAppuntamento event={event} refetch={props.refetch}>
                        <button className="bg-gray-100 bg-opacity-60 rounded-full p-2 hover:bg-gray-200 shadow">
                          <EyeIcon className="h-7 w-7 text-indigo-600" />
                        </button>
                      </ModalAppuntamento>

                      <button
                        className="bg-gray-100 bg-opacity-60 rounded-full p-2 hover:bg-gray-200 shadow"
                        onClick={() => onDeleteEvent(event)}>
                        <TrashIcon className="h-7 w-7 text-red-600" />
                      </button>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
