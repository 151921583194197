import * as yup from 'yup';

export const firstStep = yup.object().shape({
  email: yup.string().required('Inserire una email').email('Inserire una mail valida'),
});

export const secondStep = yup.object().shape({
  resetCode: yup.string().required('Inserisci il codice'),
  password: yup.string().required('Password necessaria').min(6),
  confirmPassword: yup.string().required('Conferma necessario').min(6),
});

export default {
  firstStep,
  secondStep,
};
