// main
import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import axios from 'axios';
import Layout from '../../Layout';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import { SearchIcon, RefreshIcon } from '@heroicons/react/outline';

import Pagination from '../../components/Pagination';
import FilterInput from '../../components/forms/filterinput';

const Toolbar = ({ query, setQuery, resetFilters }) => {
  return (
    <div className="w-full flex items-end gap-4 justify-between">
      <div className="flex space-x-4 items-end lg:flex">
        <FilterInput
          label="Cerca"
          name="search"
          placeholder="Cerca..."
          value={query.search}
          onChange={({ target }) => setQuery({ search: target.value, offset: 0 })}
          icon={SearchIcon}
        />

        <button
          onClick={resetFilters}
          className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <RefreshIcon className="md:-ml-1 md:mr-3 h-5 w-5" aria-hidden="true" />
          <span className="hidden md:block">Ripristina</span>
        </button>
      </div>
    </div>
  );
};

const Documentazione = () => {
  const { token } = useAuth();
  const [total, setTotal] = useState(0);
  const [pdfs, setPdfs] = useState([]);
  const { showLoading, hideLoading } = useLoading();
  const [debouncedSearch] = useDebouncedCallback(searchPdfs, 300);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
  });

  useEffect(debouncedSearch, [query]);
  useEffect(() => void searchPdfs(), [query.offset]);

  async function searchPdfs() {
    showLoading();

    try {
      const { data: { pdfs = [], total = 0 } = {} } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/pdf`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        },
      );

      setTotal(total);
      setPdfs(pdfs);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading();
    }
  }

  function resetFilters() {
    setQuery({
      search: '',
      offset: 0,
      limit: 10,
    });
  }

  return (
    <Layout
      title="Documentazione"
      toolbar={<Toolbar query={query} setQuery={setQuery} resetFilters={resetFilters} />}>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Prodotto
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Codice prodotto
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Matricola Inizio serie
                    </th>

                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Apri scheda</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray">
                  {pdfs.map((pdf) => (
                    <tr key={pdf._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="">
                            <div className="text-sm font-medium text-gray-900">
                              {pdf.prodottoFormattato}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{pdf.codiceAssistenza}</div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{pdf.daMatricola}</div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/documentazione/${pdf._id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                          target="__blank">
                          Visualizza documentazione
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination total={total} offset={query.offset} limit={query.limit} setQuery={setQuery} />
    </Layout>
  );
};

export default Documentazione;
