import Month from './month';
import Week from './week';
import Mobile from './mobile';

const Calendar = (props) => {
  if (props.user.tipo === 'tecnico') return <Mobile {...props} />;

  switch (props.view) {
    case 'week':
      return <Week {...props} />;
    case 'month':
      return <Month {...props} />;
    default:
      return <p>Seleziona una vista</p>;
  }
};

export default Calendar;
