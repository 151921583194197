import React from 'react';
import ReactPaginate from 'react-paginate';
import { ArrowRight, ArrowLeft } from '@styled-icons/feather';
import './styles/pagination.css';

function Pagination({ total = 0, limit = 15, onPageChange }) {
  const numberOfPages = total / limit;

  return (
    <ReactPaginate
      onPageChange={({ selected }) => onPageChange(selected * limit)}
      containerClassName="pagination"
      pageCount={numberOfPages}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      previousLabel={<ArrowLeft />}
      nextLabel={<ArrowRight />}
    />
  );
}

export default Pagination;
