// main
import React, { useState, useEffect } from 'react';

// routing
import { useHistory } from 'react-router-dom';

// api request
import axios from 'axios';

//

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';

// other
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { EyeIcon, XIcon, CheckIcon } from '@heroicons/react/outline';
import { PaperClipIcon } from '@heroicons/react/outline';

import Input from '../../../components/forms/input';
import TextArea from '../../../components/forms/textarea';

import { ricambioInitialValues, ricambioValidationSchema } from '../validations/validationSchema';

// Component
export default function RicambioModal({ ean13 }) {
  const { token } = useAuth();

  const [pezzi, setPezzi] = useState('');

  const [lista, setLista] = useState([]);

  const [ricambi, setRicambi] = useState([]);

  const hideModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const { showLoading, hideLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: ricambioInitialValues,
    validationSchema: ricambioValidationSchema,
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: async (values) => {
      showLoading();
      console.log(values);
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_HOST}/api/stock/${values.ricambio?.Ean13}`,
          {
            ean13: values.ricambio?.Ean13,
            Codice: values.ricambio?.Codice,
            giacenza: values.giacenza,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        toast.success('Dati aggiornati');
      } catch (e) {
        toast.error('Errore');
      } finally {
        hideLoading();
      }
    },
  });

  const carica = async () => {
    if (lista.length < 1) return toast.warning('Nessun pezzo scansionato');
    try {
      await axios.patch(`${process.env.REACT_APP_API_HOST}/api/stock/massive-load`, lista, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Successo');
      setPezzi('');
    } catch (e) {
      toast.error('Errore');
    }
  };

  useEffect(() => {
    const lista = [];
    const pezziArray = pezzi.split('\n').filter(Boolean);

    pezziArray.forEach((pezzo) => {
      const indice = lista.findIndex((elemento) => elemento?.Ean13 === pezzo);

      const ricambio = ricambi.find((elemento) => {
        return elemento.Ean13 === pezzo;
      });

      if (!ricambio) return;

      if (indice === -1) {
        lista.push({
          ...ricambio,
          quantita: 1,
        });
      }

      if (indice > -1) {
        lista[indice] = { ...ricambio, quantita: lista[indice].quantita + 1 };
      }
    });

    setLista(lista);
  }, [pezzi]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/ricambi`, {
        params: {
          offset: 0,
          limit: 10000000,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRicambi(data.ricambi);
    })();
  }, []);

  return (
    <>
      <button
        onClick={showModal}
        className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <CheckIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
        <span className="hidden lg:inline">Caricamento massivo</span>
      </button>

      <Modal
        isOpen={isOpen}
        className="absolute h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25">
        <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
          <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
            <h1 className="font-bold text-2xl">Caricamento massivo</h1>
            <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={hideModal} />
          </div>

          <div className="grid grid-rows-3 w-full h-full p-12 gap-6">
            <div className="">
              <TextArea
                label="Ricambi scansionati"
                value={pezzi}
                onChange={(e) => setPezzi(e.target.value)}
              />
            </div>
            <div className=" overflow-y-scroll">
              {lista.map((elemento) => {
                return (
                  <div className="grid grid-cols-6 mt-2 border-b-2">
                    <p className="col-span-1">{elemento.Codice}</p>
                    <p className="col-span-4">{elemento.Descrizione}</p>
                    <p className="col-span-1 flex justify-center font-semibold">
                      {elemento.quantita}
                    </p>
                  </div>
                );
              })}
            </div>

            <button
              onClick={carica}
              className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <CheckIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
              <span className="hidden lg:inline">Carica</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

Modal.setAppElement('#root');
