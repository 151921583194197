import {
  differenceInCalendarDays,
  endOfMonth,
  startOfMonth,
  isSameDay,
  isAfter,
  isBefore,
} from 'date-fns';
import { addDays, subDays } from 'date-fns/esm';
import classNames from 'classnames';

import Header from './header/monthHeader';

import { useState } from 'react';
import Weekdays from './weekdays';
import Day from './day';
import { useEffect } from 'react';

const Month = (props) => {
  const utcDate = new Date(props.selectedDate.toUTCString());
  const [daysWithEvents, setDaysWithEvents] = useState([]);

  const generateDays = (utcDate) => {
    const currentMonth = utcDate.getMonth();
    const currentYear = utcDate.getFullYear();

    const start = startOfMonth(utcDate);
    const end = endOfMonth(utcDate);

    const daysThisMonth = differenceInCalendarDays(end, start) + 1;

    let days = [];

    for (let day = 1; day <= daysThisMonth; day++) {
      days.push({
        date: new Date(currentYear, currentMonth, day),
        isCurrentMonth: true,
      });
    }

    const daysBefore = start.getDay() === 0 ? 7 : start.getDay();

    for (let day = 1; day < daysBefore; day++) {
      days.unshift({
        date: subDays(start, day),
        isCurrentMonth: false,
      });
    }

    const daysAfter = days.length > 35 ? 42 - days.length : 35 - days.length;

    for (let day = 1; day < daysAfter + 1; day++) {
      days = [
        ...days,
        {
          date: addDays(end, day),
          isCurrentMonth: false,
        },
      ];
    }

    days.length > 35 ? days.slice(0, 42) : (days = days.slice(0, 35));

    const daysWithEvents = days.map((day) => {
      const eventsThisDay = props.events.filter((event) =>
        isSameDay(new Date(event.startDate), day.date),
      );

      const eventsOrdered = eventsThisDay.sort((eventA, eventB) => {
        const dateEventA = new Date(eventA.startDate);
        const dateEventB = new Date(eventB.startDate);

        if (isBefore(dateEventA, dateEventB)) return -1;
        if (isAfter(dateEventA, dateEventB)) return 1;
        return 0;
      });

      const dayWithEvents = {
        date: day.date,
        isCurrentMonth: day.isCurrentMonth,
        events: eventsOrdered,
      };

      return dayWithEvents;
    });

    return daysWithEvents;
  };

  useEffect(() => {
    const days = generateDays(utcDate);

    setDaysWithEvents(days);
  }, [props.events]);

  const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

  return (
    <div className="h-full">
      <Header
        onChangeView={props.onChangeView}
        selectedDate={props.selectedDate}
        onChangeDate={props.onChangeDate}
      />

      <div className="flex-col h-full lg:flex">
        <Weekdays weekDays={weekDays} />

        <div className={classNames('grid grid-cols-7 flex-1 h-full', {})}>
          {daysWithEvents.map((day, idx) => {
            return (
              <Day
                key={idx}
                selectedResource={props.selectedResource}
                view={props.view}
                weekDays={weekDays}
                day={day}
                selectedDate={props.selectedDate}
                eventsLimit={props.eventsLimit || 5}
                refetch={props.refetch}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Month;
