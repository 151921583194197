// main
import React, { Fragment, useEffect, useState } from 'react';

// date-fns
import { format, set } from 'date-fns';

import classNames from 'classnames';

// api request
import axios from 'axios';

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';
// import { impiantoInitialValues, impiantoValidationSchema } from '../../Impianto/formValues';

// other

import { toast } from 'react-toastify';
import { PlusIcon, XIcon, CheckIcon } from '@heroicons/react/outline';

import Input from '../../../components/forms/input';
import TextArea from '../../../components/forms/textarea';
import Select from '../../../components/forms/select';

// Component
export default function CreaImpiantoModal(props) {
  const { token } = useAuth();

  const toggleModal = () => setIsOpen((isOpen) => !isOpen);

  const { showLoading, hideLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);
  const { values, errors, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
    initialValues: props.event,
    // validationSchema: {},
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: async (values) => {
      showLoading();

      try {
        await axios.patch(
          `${process.env.REACT_APP_API_HOST}/api/appuntamenti/${values._id}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        props.refetch();
        toast.success('Appuntamento modificato');
        toggleModal();
      } catch (e) {
        console.log(e);
        toast.error('Procodoi');
      } finally {
        hideLoading();
        props.refetch();
      }
    },
  });

  const handleDateChange = (e) => {
    const [year, month, date] = e.target.value.split('-');

    const pippo = set(new Date(values.startDate), {
      year,
      month: Number(month) - 1,
      date,
    });

    setFieldValue('startDate', pippo);
  };

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(':');

    const pippo = set(new Date(values.startDate), {
      hours,
      minutes,
    });

    setFieldValue('startDate', pippo);
  };

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, { onClick: toggleModal });
  });

  return (
    <>
      <Fragment>{children}</Fragment>

      {isOpen ? (
        <div
          className="fixed top-0 left-0 z-50 h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25"
          style={{
            zIndex: 9999,
          }}>
          <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
            <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
              <h1 className="font-bold text-2xl">Modifica appuntamento</h1>
              <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={toggleModal} />
            </div>

            <form onSubmit={handleSubmit} className="bg-white overflow-y-scroll flex-1">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Tecnico:</h3>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">title</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Input
                        label=""
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title}
                      />
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">notes</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <TextArea
                        label=""
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        error={errors.notes}
                      />
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Data</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Input
                        label=""
                        name="startDate"
                        value={format(new Date(values.startDate), 'yyyy-MM-dd')}
                        onChange={handleDateChange}
                        error={errors.startDate}
                        type="date"
                      />
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Ora</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Input
                        label=""
                        name="startDate"
                        value={format(new Date(values.startDate), 'kk:mm')}
                        onChange={handleTimeChange}
                        error={errors.startDate}
                        type="time"
                      />
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">duration (minuti)</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Input
                        label=""
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        error={errors.duration}
                        type="number"
                      />
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">type</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Select
                        label=""
                        options={[
                          { id: 'GUASTO', name: 'GUASTO' },
                          { id: 'MANUTENZIONE', name: 'MANUTENZIONE' },
                          { id: 'COLLAUDO', name: 'COLLAUDO' },
                          { id: 'ALTRO', name: 'ALTRO' },
                        ]}
                        colspan={1}
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        error={errors.type}
                      />
                    </dd>
                  </div>

                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <button
                        onClick={
                          Object.keys(errors).length > 0
                            ? () => toast.error('Controlla i campi')
                            : handleSubmit
                        }
                        className="inline-flex justify-center items-center px-6 h-14 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center md:w-auto">
                        <CheckIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
                        Salva
                      </button>
                    </dt>
                  </div>
                </dl>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}
