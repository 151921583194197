// main
import React, { useState, useEffect } from 'react';

// routing
import { useHistory } from 'react-router-dom';

// api request
import axios from 'axios';

//

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';

// other
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { EyeIcon, XIcon, CheckIcon } from '@heroicons/react/outline';
import { PaperClipIcon } from '@heroicons/react/outline';

import Input from '../../../components/forms/input';

import { ricambioInitialValues, ricambioValidationSchema } from '../validations/validationSchema';

// Component
export default function RicambioModal({ ean13 }) {
  const { token } = useAuth();
  const history = useHistory();

  const hideModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const { showLoading, hideLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: ricambioInitialValues,
    validationSchema: ricambioValidationSchema,
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: async (values) => {
      showLoading();
      console.log(values);
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_HOST}/api/stock/${values.ricambio?.Ean13}`,
          {
            ean13: values.ricambio?.Ean13,
            Codice: values.ricambio?.Codice,
            giacenza: values.giacenza,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        toast.success('Dati aggiornati');
      } catch (e) {
        toast.error('Errore');
      } finally {
        hideLoading();
      }
    },
  });

  useEffect(() => {
    (async () => {
      const ricambio = (
        await axios.get(`${process.env.REACT_APP_API_HOST}/api/ricambi/${ean13}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;

      console.log(ricambio);
      setValues(ricambio);
    })();
  }, []);

  return (
    <>
      <button
        onClick={showModal}
        className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md ">
        <EyeIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
        <span className="hidden lg:inline">Dettaglio</span>
      </button>

      <Modal
        isOpen={isOpen}
        className="absolute h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25">
        <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
          <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
            <h1 className="font-bold text-2xl">Ricambio codice: {values.ricambio?.Codice}</h1>
            <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={hideModal} />
          </div>

          <form onSubmit={handleSubmit} className="bg-white overflow-y-scroll flex-1">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Giacenza: {values.giacenza || 'nessuno'}
              </h3>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Categoria</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Categoria}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Sottocategoria</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Sottocategoria}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Descrizione</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Descrizione}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Codice</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Codice}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Altri codici</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="list-disc">
                      {values.ricambio?.A && <li>{values.ricambio?.A}</li>}
                      {values.ricambio?.B && <li>{values.ricambio?.B}</li>}
                      {values.ricambio?.C && <li>{values.ricambio?.C}</li>}
                      {values.ricambio?.D && <li>{values.ricambio?.D}</li>}
                      {values.ricambio?.E && <li>{values.ricambio?.E}</li>}
                    </ul>
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Prezzo di listino</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Prezzo} €
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">EAN13</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.ricambio?.Ean13}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                  <dt className="text-sm font-medium text-gray-500">Giacenza</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input
                      colspan={2}
                      label=""
                      name="giacenza"
                      value={values.giacenza}
                      onChange={handleChange}
                      error={errors.giacenza}
                      type="number"
                    />
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <button
                      onClick={
                        Object.keys(errors).length > 0
                          ? () => toast.error('Controlla i campi')
                          : handleSubmit
                      }
                      className="inline-flex justify-center items-center px-6 h-14 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center md:w-auto">
                      <CheckIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
                      Salva
                    </button>
                  </dt>
                </div>
              </dl>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

Modal.setAppElement('#root');
