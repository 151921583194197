// main
import converter from 'json-2-csv';
import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import axios from 'axios';
import Layout from '../../Layout';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';

import {
  UserIcon,
  PhoneIcon,
  MapIcon,
  LocationMarkerIcon,
  RefreshIcon,
  SearchIcon,
  DownloadIcon,
} from '@heroicons/react/outline';

import Pagination from '../../components/Pagination';
import FilterInput from '../../components/forms/filterinput';
import CreaImpiantoModal from './components/CreaImpiantoModal';
import FiltersModal from './components/FiltersModal';

const Toolbar = ({ query, setQuery, resetFilters, getAllImpianti }) => {
  return (
    <div className="w-full flex items-end gap-4 justify-between">
      <div className="w-full flex items-center justify-end lg:hidden">
        <FiltersModal query={query} setQuery={setQuery} resetFilters={resetFilters} />
      </div>
      <div className="hidden space-x-4 items-end lg:flex">
        <FilterInput
          label="Cliente"
          name="cliente"
          placeholder="Cerca..."
          value={query.cliente}
          onChange={({ target }) => setQuery({ cliente: target.value, offset: 0 })}
          icon={UserIcon}
        />

        <FilterInput
          label="Telefono"
          name="telefono"
          placeholder="Cerca..."
          value={query.telefono}
          onChange={({ target }) => setQuery({ telefono: target.value, offset: 0 })}
          icon={PhoneIcon}
        />

        <FilterInput
          label="Comune"
          name="comune"
          placeholder="Cerca..."
          value={query.comune}
          onChange={({ target }) => setQuery({ comune: target.value, offset: 0 })}
          icon={MapIcon}
        />

        <FilterInput
          label="Indirizzo"
          name="indirizzo"
          placeholder="Cerca..."
          value={query.indirizzo}
          onChange={({ target }) => setQuery({ indirizzo: target.value, offset: 0 })}
          icon={LocationMarkerIcon}
        />

        <button
          onClick={resetFilters}
          className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <RefreshIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Ripristina
        </button>
      </div>

      <button
        onClick={getAllImpianti}
        className="hidden lg:flex inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <DownloadIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
        <span className="hidden lg:inline">Esporta</span>
      </button>

      <CreaImpiantoModal />
    </div>
  );
};

const Impianti = () => {
  const { token } = useAuth();
  const [total, setTotal] = useState(0);
  const [impianti, setImpianti] = useState([]);
  const { showLoading, hideLoading } = useLoading();
  const [debouncedSearch] = useDebouncedCallback(searchImpianti, 300);

  const getAllImpianti = async () => {
    showLoading();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/impianti/export`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const totalFields = [];
      Object.keys(data.impianti[0]).map((key) => {
        Object.keys(data.impianti[0][key]).map((keyy) => totalFields.push(`${key}.${keyy}`));
      });

      converter.json2csv(data.impianti, (err, csv) => {
        const element = document.createElement('a');
        const file = new Blob([csv], {
          type: 'text/csv',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'export.csv';
        document.body.appendChild(element);
        element.click();
      });
    } catch (e) {
      console.log(e);
    }
    hideLoading();
  };

  const [query, setQuery] = useQueryParams({
    cliente: withDefault(StringParam, ''),
    telefono: withDefault(StringParam, ''),
    comune: withDefault(StringParam, ''),
    indirizzo: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
  });

  useEffect(debouncedSearch, [query]);
  useEffect(() => void searchImpianti(), [query.offset]);

  async function searchImpianti() {
    showLoading();

    try {
      const { data: { impianti = [], total = 0 } = {} } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/impianti`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        },
      );

      setTotal(total);
      setImpianti(impianti);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading();
    }
  }

  function resetFilters() {
    setQuery({
      cliente: '',
      telefono: '',
      comune: '',
      indirizzo: '',
      offset: 0,
      limit: 10,
    });
  }

  return (
    <Layout
      title="Lista impianti"
      toolbar={
        <Toolbar
          query={query}
          setQuery={setQuery}
          resetFilters={resetFilters}
          getAllImpianti={getAllImpianti}
        />
      }>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Responsabile
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ubicazione
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Generatore
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estensione garanzia
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Apri scheda</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray">
                  {impianti.map((impianto) => (
                    <tr key={impianto._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="">
                            <div className="text-sm font-medium text-gray-900">
                              {impianto.responsabile.cognome} {impianto.responsabile.nome}
                            </div>
                            <div className="text-sm text-gray-500">
                              {impianto.responsabile.annotazioni}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {impianto.ubicazione.comune}{' '}
                          {impianto.ubicazione.provincia
                            ? `(${impianto.ubicazione.provincia})`
                            : null}
                        </div>
                        <div className="text-sm text-gray-500">
                          {impianto.ubicazione.indirizzo}{' '}
                          {impianto.ubicazione.civico ? `, ${impianto.ubicazione.civico}` : null}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{impianto.generatore.modello}</div>
                        <div className="text-sm text-gray-500">{impianto.generatore.matricola}</div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {impianto.generatore.formula_comfort !== '' && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {impianto.generatore.formula_comfort}
                          </span>
                        )}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/impianti/${impianto._id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                          target="_blank">
                          Visualizza scheda
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination total={total} offset={query.offset} limit={query.limit} setQuery={setQuery} />
    </Layout>
  );
};

export default Impianti;
