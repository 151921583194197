import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { toast } from 'react-toastify';
import axios from 'axios';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import Layout from '../../Layout';

import { Dati, Form, Label, Input, Error, Button, Select } from '../../styles/App';

import validationSchema from './components/validations/validationLavoratore';

export default function Impianto() {
  const { showLoading, hideLoading } = useLoading();
  const { token } = useAuth();

  const { idLavoratore } = useParams();

  const history = useHistory();

  const { values, errors, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
    initialValues: {
      nome: '',
      congome: '',
      email: '',
      tipo: '',
      calendarAuth: '',
      calendarApiKey: '',
    },
    validateOnChange: true,
    validateOnSubmit: true,
    validationSchema,

    onSubmit: (values) => {
      showLoading();
      axios
        .patch(`${process.env.REACT_APP_API_HOST}/api/lavoratori/${idLavoratore}`, values, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => {
          const { status, data } = r;
          if (status === 200) return toast.success(data.message);
          // return toast.warning(data.message);
        })
        .catch((e) => console.log(e))
        .finally(hideLoading());
    },
  });

  const eliminaLavoratore = () => {
    axios
      .delete(`${process.env.REACT_APP_API_HOST}/api/lavoratori/${idLavoratore}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        toast.success('Eliminato con successo!');
        history.push('/lavoratori');
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    showLoading();
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/lavoratori/${idLavoratore}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setValues(data);
        console.log(data, 'diocane');
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => hideLoading());
  }, []);

  // const nuovoContatto = () => {
  //
  // };

  // const eliminaContatto = (indexContatto) => {
  //
  // };

  const [debouncedSalva] = useDebouncedCallback(handleSubmit, 1500);
  useEffect(debouncedSalva, [values]);

  const { nome, cognome, email, tipo, isActive, calendarApiKey, calendarAuth } = values;
  console.log(errors);
  return (
    <Layout title={`${nome} ${cognome}`}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Dati>
          <h1>Dati responsabile</h1>

          <Label className={errors?.tipo ? 'error' : null}>
            Tipo:
            <Select name="tipo" value={tipo} onChange={handleChange}>
              <option value="impiegato">Impiegato</option>
              <option value="tecnico">Tecnico</option>
            </Select>
            <Error>{errors?.tipo ? errors.tipo : null}</Error>
          </Label>

          <Label className={errors?.nome ? 'error' : null}>
            Nome:
            <Input type="text" name="nome" value={nome} onChange={handleChange} />
            <Error>{errors?.nome ? errors.nome : null}</Error>
          </Label>
          <Label className={errors?.cognome ? 'error' : null}>
            cognome:
            <Input type="text" name="cognome" value={cognome} onChange={handleChange} />
            <Error>{errors?.cognome ? errors.cognome : null}</Error>
          </Label>
          <Label className={errors?.email ? 'error' : null}>
            E-mail:
            <Input type="text" name="email" value={email} onChange={handleChange} />
            <Error>{errors?.email ? errors.email : null}</Error>
          </Label>

          <Label className={errors?.calendarApiKey ? 'error' : null}>
            30 Boxes API KEY:
            <Input
              type="text"
              name="calendarApiKey"
              value={calendarApiKey}
              onChange={handleChange}
            />
            <Error>{errors?.calendarApiKey ? errors.calendarApiKey : null}</Error>
          </Label>
          <Label className={errors?.calendarAuth ? 'error' : null}>
            30 Boxes AUTH CODE:
            <Input type="text" name="calendarAuth" value={calendarAuth} onChange={handleChange} />
            <Error>{errors?.calendarAuth ? errors.calendarAuth : null}</Error>
          </Label>
          <Label>
            <p style={{ color: isActive ? '' : '#E75A7C' }}>
              Stato: {isActive ? 'Attivo' : 'Non attivo'}
            </p>
          </Label>
          <Button type="submit" onClick={handleSubmit}>
            Salva
          </Button>
        </Dati>
      </Form>
    </Layout>
  );
}
