import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  color: var(--title);
`;

export const Toolbox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Form = styled.form`
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 2fr;
`;

export const DataRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--text);
  position: relative;

  & p {
    flex: 1;
    max-width: 150px;
  }

  &.error {
    color: var(--highlight);
    & > input {
      border-color: red;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
export const Input = styled.input`
  border: none;
  outline: none;
  flex: 3;
  height: 40px;
  border: 1px solid var(--downlight);
  border-radius: 3px;
  font-size: 14px;
  padding: 0 0.25rem;
`;

export const Textarea = styled.textarea`
  border: none;
  outline: none;
  flex: 3;
  height: 40px;
  border: 1px solid var(--downlight);
  border-radius: 3px;
  font-size: 14px;
  height: 120px;
  padding: 0 0.25rem;
  resize: vertical;
`;

export const Select = styled.select`
  border: 1px solid;
  outline: none;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin-top: 0.25rem;
  height: 40px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: none;

  cursor: pointer;

  padding: 0 0.5rem;
  border-radius: 3px;

  transition: 0.1s ease;

  background-color: var(--title);
  color: var(--white);

  height: 40px;

  &.delete {
    background-color: var(--highlight);
  }
  & > svg {
    height: 20px;
  }

  &.save {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    gap: 0.5rem;
    height: 50px;
    & svg {
      height: 30px;
    }
  }
`;

export const Error = styled.span`
  font-size: 12px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  border-bottom: 1px solid var(--downlight);
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableRow = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  font-weight: normal;
  color: var(--title);
  min-height: 40px;

  &.interactive {
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
      color: var(--highlight);
      border-bottom-color: var(--highlight);
    }
  }

  &.fc {
    background-color: rgba(0, 255, 0, 0.2);
  }

  &.error {
    background-color: #e75a7c;
  }
`;

export const TableCell = styled.span`
  flex: 1;
  display: flex;
  align-items: flex-end;
  &.warning {
    color: var(--highlight);
  }
  & svg {
    width: 20px;
    justify-self: center;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  width: 500px;
  border: 1px solid var(--downlight);
  padding: 0.33rem 0.66rem;

  & svg {
    height: 20px;
    padding: 0 0.5rem;
  }
  flex: 1;
  &.full {
    flex: 3;
  }
`;

export const FilterInput = styled.input`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  flex: 1;
  font-size: 16px;
`;

export const Dati = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  & .container {
    display: flex;
    grid-gap: 1rem;

    & > * {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
`;
