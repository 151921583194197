// main
import React, { useEffect, useState } from 'react';

import { XIcon, TrashIcon, EyeIcon, PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { addMinutes, format, isBefore, isAfter } from 'date-fns';
import ModalAppuntamento from './ModalAppuntamento';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../Context/AuthContext';
import Input from '../../../components/forms/input';

import classNames from 'classnames';

// Component
export default function ModalRicerca(props) {
  const { token } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const [events, setEvents] = useState([]);

  const [search, setSearch] = useState('');

  const toggleModal = () => setIsOpen((isOpen) => !isOpen);

  const onSearch = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/appuntamenti`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          search: search,
        },
      });
      const events = data.map((event) => {
        return {
          ...event,
          startDate: new Date(event.startDate),
        };
      });

      const eventsOrdered = events.sort((eventA, eventB) => {
        const dateEventA = new Date(eventA.startDate);
        const dateEventB = new Date(eventB.startDate);

        if (isBefore(dateEventA, dateEventB)) return 1;
        if (isAfter(dateEventA, dateEventB)) return -1;
        return 0;
      });
      console.log(search);
      setEvents(eventsOrdered);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div>
        <button
          onClick={toggleModal}
          className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <SearchIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
          <span className="hidden lg:inline">Cerca</span>
        </button>
      </div>

      {isOpen ? (
        <div
          className="fixed top-0 left-0 z-50 h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25"
          style={{
            zIndex: 9999,
          }}>
          <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
            <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
              <h1 className="font-bold text-2xl">Cerca appuntamento</h1>
              <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={toggleModal} />
            </div>
            <div className="flex gap-4 mx-6 justify-center items-end">
              <div className="flex-1">
                <Input
                  name="searchEvent"
                  type="text"
                  label=""
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="cerca appuntamento"
                  classNameExtensions="flex-1 mt-2"
                />
              </div>
              <button
                onClick={onSearch}
                className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <SearchIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
                <span className="hidden lg:inline">Cerca</span>
              </button>
            </div>
            <div className="overflow-y-scroll w-full">
              <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
                {events.map((event, idx) => {
                  return (
                    <ModalAppuntamento key={event._id} event={event} refetch={props.refetch}>
                      <li className="flex w-full p-8">
                        <div className="flex-1">
                          <div className="flex flex-col">
                            <p className="text-base flex-none">
                              <time dateTime="2022-01-13T14:30">
                                {format(event.startDate, 'dd/MM/yyyy HH:mm')}
                              </time>
                              {' | '}
                              <span>
                                {event.resourceId.nome} {event.resourceId.cognome}
                              </span>
                            </p>
                            <p
                              className={classNames(
                                'text-xl mt-2 flex-auto font-regular text-gray-900 sm:mt-0',
                                {
                                  'text-green-500': event.type === 'MANUTENZIONE',
                                  'text-red-500': event.type === 'GUASTO',
                                  'text-blue-500': event.type === 'ALTRO',
                                  'text-yellow-500': event.type === 'COLLAUDO',
                                },
                              )}>
                              {event.title}
                            </p>
                          </div>

                          {event.notes !== '' && (
                            <p className="text-base w-full mt-2 p-2 rounded bg-gray-100 text-gray-900">
                              {event.notes}
                            </p>
                          )}
                        </div>
                      </li>
                    </ModalAppuntamento>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
