import React from 'react';
import { toast } from 'react-toastify';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../Context/AuthContext';

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const { isLogged, user } = useAuth();

  return (
    <Route
      render={(props) => {
        if (isLogged) {
          if (user.tipo === 'admin') history.push('/lavoratori');
          if (user.tipo !== 'admin') history.push('/impianti');
        }
        if (!isLogged) return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

export default PublicRoute;
