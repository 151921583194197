import React from 'react';

import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Button } from '../../styles/App';

import onyx from '../../images/onyx.png';

const ContainerNotFound = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Code = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  & h2 {
    font-size: 36px;
    color: var(--highlight);

    padding: 0;
  }
  & h1 {
    font-size: 48px;
    margin: 0;
    padding: 0;
  }

  & p {
    font-size: 24px;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const Onyx = styled.img`
  border-radius: 100%;
  background: var(--downlight);
  box-shadow: inset 10px -10px 10px 5px rgba(0, 0, 0, 0.3);
`;
const NotFound = () => {
  const history = useHistory();
  return (
    <ContainerNotFound>
      <Code>
        <Onyx src={onyx} alt="Onyx idi suda" />
        <Message>
          <h1>
            Oh no!
            <br />
            Hai disturbato Onyx!
          </h1>
          <p>Qua non c'è nessuno a parte lui, meglio se torni indietro.</p>
          <Button onClick={() => history.replace('/')}>Torna alla home</Button>
        </Message>
      </Code>
    </ContainerNotFound>
  );
};

export default NotFound;
