// main
import React, { useState } from 'react';

// routing
import { useHistory } from 'react-router-dom';

// api request
import axios from 'axios';

// style

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';
import { impiantoInitialValues, impiantoValidationSchema } from '../../Impianto/formValues';

// other
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { PlusIcon, XIcon, CheckIcon } from '@heroicons/react/outline';

import Input from '../../../components/forms/input';
import TextArea from '../../../components/forms/textarea';
import Select from '../../../components/forms/select';

// Component
export default function CreaImpiantoModal() {
  const { token } = useAuth();
  const history = useHistory();

  const hideModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const { showLoading, hideLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: impiantoInitialValues,
    validationSchema: impiantoValidationSchema,
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: async (values) => {
      showLoading();

      try {
        const {
          data: { id_impianto },
        } = await axios.post(`${process.env.REACT_APP_API_HOST}/api/impianti`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        toast.success('Impianto creato');
        history.push(`/impianti/${id_impianto}`);
      } catch (e) {
        toast.success('Impianto creato');
      } finally {
        hideLoading();
      }
    },
  });

  return (
    <>
      <button
        onClick={showModal}
        className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <PlusIcon className="h-6 w-6 lg:-ml-1 lg:mr-3" aria-hidden="true" />
        <span className="hidden lg:inline">Nuovo</span>
      </button>

      <Modal
        isOpen={isOpen}
        className="absolute h-screen w-screen flex flex-col justify-center items-center bg-black bg-opacity-25">
        <div className="h-full w-full lg:h-3/4 lg:w-lg overflow-y-hidden md:shadow-md bg-white flex flex-col lg:rounded-lg">
          <div className="top-0 z-10 inset-x-0 flex justify-between px-8 lg:px-12 py-6 bg-indigo-500 text-white items-center">
            <h1 className="font-bold text-2xl">Crea nuovo impianto</h1>
            <XIcon className="h-7 w-7 cursor-pointer -m-3 md:mr-1" onClick={hideModal} />
          </div>

          <form onSubmit={handleSubmit} className="pl-6 pr-4 bg-white overflow-y-scroll flex-1">
            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Responsabile</h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Input
                  colspan={3}
                  label="Nome"
                  name="responsabile.nome"
                  value={values.responsabile.nome}
                  onChange={handleChange}
                  error={errors.responsabile?.nome}
                />

                <Input
                  colspan={3}
                  label="Cognome"
                  name="responsabile.cognome"
                  value={values.responsabile.cognome}
                  onChange={handleChange}
                  error={errors.responsabile?.cognome}
                />

                <Input
                  colspan={2}
                  label="Codice fiscale / P.IVA"
                  name="responsabile.codice_fiscale"
                  value={values.responsabile.codice_fiscale}
                  onChange={handleChange}
                  error={errors.responsabile?.codice_fiscale}
                />

                <Input
                  colspan={4}
                  label="RAGIONE SOCIALE"
                  name="responsabile.ragione_sociale"
                  value={values.responsabile.ragione_sociale}
                  onChange={handleChange}
                  error={errors.responsabile?.ragione_sociale}
                />

                <TextArea
                  colspan={6}
                  label="Annotazioni cliente"
                  name="responsabile.annotazioni"
                  value={values.responsabile.annotazioni}
                  onChange={handleChange}
                  error={errors.responsabile?.annotazioni}
                />
              </div>
            </div>

            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Indirizzo</h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Select
                  label="Provincia"
                  options={[
                    { id: 'MN', name: 'MN' },
                    { id: 'BS', name: 'BS' },
                    { id: 'VR', name: 'VR' },
                  ]}
                  colspan={1}
                  name="ubicazione.provincia"
                  value={values.ubicazione.provincia}
                  onChange={handleChange}
                  error={errors.ubicazione?.provincia}
                />

                <Input
                  label="Comune"
                  colspan={3}
                  name="ubicazione.comune"
                  value={values.ubicazione.comune}
                  onChange={handleChange}
                  error={errors.ubicazione?.comune}
                />

                <Input
                  label="Località"
                  colspan={2}
                  name="ubicazione.localita"
                  value={values.ubicazione.localita}
                  onChange={handleChange}
                  error={errors.ubicazione?.localita}
                />

                <Input
                  label="Indirizzo"
                  colspan={4}
                  name="ubicazione.indirizzo"
                  value={values.ubicazione.indirizzo}
                  onChange={handleChange}
                  error={errors.ubicazione?.indirizzo}
                />

                <Input
                  label="Civico"
                  colspan={2}
                  name="ubicazione.civico"
                  value={values.ubicazione.civico}
                  onChange={handleChange}
                  error={errors.ubicazione?.civico}
                />

                <Input
                  label="Piano"
                  colspan={2}
                  name="ubicazione.piano"
                  value={values.ubicazione.piano}
                  onChange={handleChange}
                  error={errors.ubicazione?.piano}
                />

                <Input
                  label="Interno"
                  colspan={2}
                  name="ubicazione.interno"
                  value={values.ubicazione.interno}
                  onChange={handleChange}
                  error={errors.ubicazione?.interno}
                />

                <Input
                  label="Scala"
                  colspan={2}
                  name="ubicazione.scala"
                  value={values.ubicazione.scala}
                  onChange={handleChange}
                  error={errors.ubicazione?.scala}
                />

                <TextArea
                  label="Altre indicazioni"
                  name="ubicazione.annotazioni"
                  value={values.ubicazione.annotazioni}
                  onChange={handleChange}
                  error={errors.ubicazione?.annotazioni}
                />
              </div>
            </div>

            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Contatti</h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Input
                  label="Fisso"
                  colspan={6}
                  name="contatti.casa"
                  value={values.contatti.casa}
                  onChange={handleChange}
                  error={errors.contatti?.casa}
                />

                <Input
                  label="Mobile"
                  colspan={6}
                  name="contatti.mobile"
                  value={values.contatti.mobile}
                  onChange={handleChange}
                  error={errors.contatti?.mobile}
                />

                <Input
                  label="E-mail"
                  colspan={6}
                  name="contatti.email"
                  value={values.contatti.email}
                  onChange={handleChange}
                  error={errors.contatti?.email}
                />

                <TextArea
                  label="Storico chiamate"
                  name="contatti.chiamate"
                  value={values.contatti.chiamate}
                  onChange={handleChange}
                  error={errors.contatti?.chiamate}
                />
              </div>
            </div>

            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Impianto</h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Input
                  label="Targa impianto"
                  colspan={6}
                  name="curit.targa"
                  value={values.curit.targa}
                  onChange={handleChange}
                  error={errors.curit?.targa}
                />

                {/* <TextArea
                label="ANNOTAZIONI"
                name="curit.annotazioni"
                value={values.curit.annotazioni}
                onChange={handleChange}
              /> */}

                <Input
                  label="Modello"
                  colspan={4}
                  name="generatore.modello"
                  value={values.generatore.modello}
                  onChange={handleChange}
                  error={errors.generatore?.modello}
                />

                <Input
                  label="Matricola"
                  colspan={2}
                  name="generatore.matricola"
                  value={values.generatore.matricola}
                  onChange={handleChange}
                  error={errors.generatore?.matricola}
                />

                <Input
                  label="Collaudo"
                  colspan={2}
                  name="generatore.collaudo"
                  value={values.generatore.collaudo}
                  onChange={handleChange}
                  error={errors.generatore?.collaudo}
                />

                <Input
                  label="Formula comfort"
                  colspan={2}
                  name="generatore.formula_comfort"
                  value={values.generatore.formula_comfort}
                  onChange={handleChange}
                  error={errors.generatore?.formula_comfort}
                />
                <Input
                  label="Scadenza Garanzia"
                  colspan={2}
                  name="generatore.scadenza_garanzia"
                  value={values.generatore.scadenza_garanzia}
                  onChange={handleChange}
                  error={errors.generatore?.scadenza_garanzia}
                />
              </div>
            </div>

            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Manutenzioni e interventi
                </h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Input
                  label="Mese controllo"
                  colspan={4}
                  name="manutenzioni.mese_controllo"
                  value={values.manutenzioni.mese_controllo}
                  onChange={handleChange}
                  error={errors.manutenzioni?.mese_controllo}
                />

                <Input
                  label="Prossimo controllo"
                  colspan={2}
                  name="manutenzioni.prossimo_controllo"
                  value={values.manutenzioni.prossimo_controllo}
                  onChange={handleChange}
                  error={errors.manutenzioni?.prossimo_controllo}
                />

                <Input
                  label="Ultimo controllo"
                  colspan={3}
                  name="manutenzioni.ultimo_controllo"
                  value={values.manutenzioni.ultimo_controllo}
                  onChange={handleChange}
                  error={errors.manutenzioni?.ultimo_controllo}
                />

                <Input
                  label="Ultima prova fumi"
                  colspan={3}
                  name="manutenzioni.ultimo_fumi"
                  value={values.manutenzioni.ultimo_fumi}
                  onChange={handleChange}
                  error={errors.manutenzioni?.ultimo_fumi}
                />

                <TextArea
                  label="Interventi"
                  colspan={6}
                  name="manutenzioni.interventi"
                  value={values.manutenzioni.interventi}
                  onChange={handleChange}
                  error={errors.manutenzioni?.interventi}
                />
              </div>
            </div>

            <div className="px-4 py-5 bg-white sm:p-6 space-y-3">
              <div className="border-b-2 border-gray">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Altre informazioni</h3>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <Input
                  label="Idraulico"
                  colspan={4}
                  name="generatore.idraulico"
                  value={values.generatore.idraulico}
                  onChange={handleChange}
                  error={errors.generatore?.idraulico}
                />

                <Input
                  label="Di.Co"
                  colspan={2}
                  name="generatore.dico"
                  value={values.generatore.dico}
                  onChange={handleChange}
                  error={errors.generatore?.dico}
                />

                <Input
                  label="Causale installazione"
                  name="generatore.causale_installazione"
                  value={values.generatore.causale_installazione}
                  onChange={handleChange}
                  error={errors.generatore?.causale_installazione}
                />
                <TextArea
                  label="Commenti generatore"
                  colspan={6}
                  name="generatore.annotazioni"
                  value={values.generatore.annotazioni}
                  onChange={handleChange}
                  error={errors.generatore?.annotazioni}
                />
              </div>
            </div>
            <div className="flex justify-between px-4 py-5">
              <button
                onClick={
                  Object.keys(errors).length > 0
                    ? () => toast.error('Controlla i campi')
                    : handleSubmit
                }
                className="inline-flex justify-center items-center px-6 h-14 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center md:w-auto">
                <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Crea impianto
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

Modal.setAppElement('#root');
