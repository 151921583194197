import React from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { useAuth } from '../../Context/AuthContext';
import { useLoading } from '../../Context/LoadingContext';

import {
  Page,
  Container,
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
  CustomLink,
  Label,
  Input,
  Button,
  Error,
} from '../style';

import { Mail, Lock } from 'styled-icons/entypo';

export default function Login() {
  const { login } = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { email: '', password: '' },
    validateOnChange: true,
    validateOnSubmit: true,
    validationSchema: yup.object().shape({
      email: yup.string().required('Inserire una email').email('Inserire una mail valida'),
      password: yup.string().required('Inserire una password'),
    }),
    onSubmit: (v) => {
      const { email, password } = v;
      showLoading();
      axios
        .post(`${process.env.REACT_APP_API_HOST}/auth/login`, {
          email: email,
          password: password,
        })
        .then((r) => {
          const { token } = r.data;
          login(token);
          toast.info('Login effettuato');
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          hideLoading();
        });
    },
  });

  const { email, password } = values;
  return (
    <Page>
      <Container>
        <ContainerHeader>
          <h1>Accedi</h1>
          <h4>Immetti le tue credenziali per accedere al software</h4>
        </ContainerHeader>

        <ContainerBody>
          <Label className={errors?.email ? 'error' : null}>
            <Mail />
            <Input
              type="text"
              name="email"
              value={email}
              placeholder="Inserisci la tua e-mail"
              onChange={handleChange}
            />
            <Error>{errors?.email ? errors.email : null}</Error>
          </Label>
          <Label className={errors?.password ? 'error' : null}>
            <Lock />
            <Input
              type="password"
              name="password"
              value={password}
              placeholder="Inserisci la password"
              onChange={handleChange}
            />
            <Error>{errors?.password ? errors.password : null}</Error>
          </Label>
          <ContainerFooter>
            <Button type="submit" onClick={handleSubmit}>
              Login
            </Button>
          </ContainerFooter>
        </ContainerBody>
      </Container>

{/* <p>
        Non hai un account? <CustomLink to="register">Registrati</CustomLink>
      </p>
      <p>
        Password dimenticata? <CustomLink to="forgotpassword">Reimposta password</CustomLink>
      </p> 
   */}
    </Page>
  );
}
