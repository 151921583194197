import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  password: yup.string().min(6).required('Password richiesta'),
  confirmPassword: yup
    .string()
    .min(6)
    .oneOf([yup.ref('password'), null], 'Le password non combaciano')
    .required('Conferma password richiesta'),
});

export const initialValues = {
  password: '',
  confirmPassword: '',
};
