import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import App from './App';

import { LoadingProvider } from './Context/LoadingContext';
import { AuthProvider } from './Context/AuthContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <LoadingProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
            <App />
          </LoadingProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));
