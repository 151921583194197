import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useHistory } from 'react-router-dom';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import Layout from '../../Layout';

import {
  Filter,
  FilterInput,
  Header,
  PageTitle,
  Toolbox,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../../styles/App';
import { Search } from '@styled-icons/feather';

import CreaLavoratoreModal from './components/CreaLavoratoreModal';
import Pagination from './components/Pagination';
import { useDebouncedCallback } from 'use-debounce';

const Lavoratore = ({ lavoratore }) => {
  const history = useHistory();

  const { _id, nome, cognome, tipo, isActive } = lavoratore;

  return (
    <TableRow onClick={() => history.push(`/lavoratori/${_id}`)} className="interactive">
      <TableCell>
        {nome} {cognome}
      </TableCell>
      <TableCell>{tipo}</TableCell>
      <TableCell>{isActive ? 'Attivo' : 'Non attivo'}</TableCell>;
    </TableRow>
  );
};

const Lavoratori = () => {
  const { showLoading, hideLoading } = useLoading();
  const { token } = useAuth();

  function searchImpianti() {
    showLoading();
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/lavoratori`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { search, ...filters },
      })
      .then(({ data }) => {
        const { lavoratori, total } = data;
        setFilters({ ...filters, total });
        setLavoratori(lavoratori);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        hideLoading();
      });
  }

  const [debouncedSearch] = useDebouncedCallback(searchImpianti, 300);

  const [lavoratori, setLavoratori] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    total: 0,
    offset: 0,
    limit: 20,
  });

  const handleChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch();
  };

  useEffect(() => searchImpianti(), [filters.offset]);

  return (
    <Layout title="Lista lavoratori">
      <Header>
        <PageTitle>Lista lavoratori</PageTitle>
        <Toolbox>
          <Filter>
            <Search />
            <FilterInput type="text" placeholder="cerca..." onChange={handleChange} />
          </Filter>

          <CreaLavoratoreModal />
        </Toolbox>
      </Header>

      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Nome e cognome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Attivo</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {lavoratori.map((lavoratore) => (
            <Lavoratore key={lavoratore._id} lavoratore={lavoratore} />
          ))}
        </TableBody>
      </Table>

      <Pagination
        total={filters.total}
        limit={filters.limit}
        onPageChange={(offset) => {
          setFilters({ ...filters, offset });
        }}
      />
    </Layout>
  );
};

export default Lavoratori;
