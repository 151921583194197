import React, { useEffect } from 'react';

import { Redirect, useHistory, useParams } from 'react-router-dom';

import { useDebouncedCallback } from 'use-debounce';

import { Page, Container, ContainerHeader, ContainerFooter, Button } from '../style';

export default function Confirm() {
  const history = useHistory();
  const { alertType } = useParams();

  if (alertType === 'registrationConfirmed')
    return (
      <Page>
        <Container>
          <ContainerHeader>
            <h1>Account registrato!</h1>
            <p>Controlla la tua casella di posta</p>
          </ContainerHeader>
        </Container>
      </Page>
    );
  if (alertType === 'accountConfirmed')
    return (
      <Page>
        <Container>
          <ContainerHeader>
            <h1>Account attivato con successo!</h1>
            <p>Ora effettua il login per accedere al software</p>
          </ContainerHeader>
          <ContainerFooter>
            <Button onClick={() => history.push('/login')}>Effettua il login</Button>
          </ContainerFooter>
        </Container>
      </Page>
    );

  if (alertType === 'companyConfirmed')
    return (
      <Page>
        <Container>
          <ContainerHeader>
            <h1>Azienda registrata correttamente!</h1>
            <p>Effettua il login per gestire i dipendenti</p>
          </ContainerHeader>
          <ContainerFooter>
            <Button onClick={() => history.push('/login')}>Effettua il login</Button>
          </ContainerFooter>
        </Container>
      </Page>
    );

  if (alertType === 'error')
    return (
      <Page>
        <Container>
          <ContainerHeader>
            <h1>Qualcosa è andato storto</h1>
          </ContainerHeader>
        </Container>
      </Page>
    );

  return <Redirect to="/404" />;
}
