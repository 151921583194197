import React from 'react';

import Layout from '../../Layout/index';

export default function Dashboard() {
  return (
    <Layout title="dashboard">
      <h1>Dashboard</h1>
    </Layout>
  );
}
