/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react';
import Header from './header/monthHeader';

import {
  format,
  startOfMonth,
  endOfMonth,
  differenceInCalendarDays,
  subDays,
  addDays,
  isSameDay,
  addMinutes,
  isBefore,
  isAfter,
  isSameMonth,
} from 'date-fns';

import ModalAppuntamento from '../ModalAppuntamento';

import classNames from 'classnames';
import ModalNuovoAppuntamento from '../ModalNuovoAppuntamento';

export default function Mobile(props) {
  const [days, setDays] = useState([]);
  const [events, setEvents] = useState([]);

  const generateDays = (utcDate) => {
    const currentMonth = utcDate.getMonth();
    const currentYear = utcDate.getFullYear();

    const start = startOfMonth(utcDate);
    const end = endOfMonth(utcDate);

    const daysThisMonth = differenceInCalendarDays(end, start) + 1;

    let daysGenerated = [];

    for (let day = 1; day <= daysThisMonth; day++) {
      daysGenerated.push({
        date: new Date(currentYear, currentMonth, day),
        isCurrentMonth: true,
      });
    }

    const daysBefore = start.getDay() === 0 ? 7 : start.getDay();

    for (let day = 1; day < daysBefore; day++) {
      daysGenerated.unshift({
        date: subDays(start, day),
        isCurrentMonth: false,
      });
    }
    const daysAfter =
      daysGenerated.length > 35 ? 42 - daysGenerated.length : 35 - daysGenerated.length;

    for (let day = 1; day < daysAfter + 1; day++) {
      daysGenerated = [
        ...daysGenerated,
        {
          date: addDays(end, day),
          isCurrentMonth: false,
        },
      ];
    }

    daysGenerated.length > 35 ? daysGenerated.slice(0, 42) : daysGenerated.slice(0, 35);

    return daysGenerated;
  };

  function getEvents(selectedDate) {
    const events = props.events.filter((event) => {
      return isSameDay(event.startDate, selectedDate);
    });
    const eventsOrdered = events.sort((eventA, eventB) => {
      const dateEventA = new Date(eventA.startDate);
      const dateEventB = new Date(eventB.startDate);

      if (isBefore(dateEventA, dateEventB)) return -1;
      if (isAfter(dateEventA, dateEventB)) return 1;
      return 0;
    });
    console.log(eventsOrdered);
    return eventsOrdered;
  }

  useEffect(() => {
    setDays(generateDays(props.selectedDate));
  }, [props.selectedResource]);

  useEffect(() => {
    setEvents(getEvents(props.selectedDate));
    setDays(generateDays(props.selectedDate));
  }, [props.events, props.selectedDate]);

  const getFrom = (date) => {
    try {
      return format(date, 'HH:mm');
    } catch (e) {
      return '00:00';
    }
  };
  const getTo = (date, duration) => {
    try {
      return format(addMinutes(date, duration), 'HH:mm');
    } catch (e) {
      return '00:00';
    }
  };

  return (
    <div>
      <Header
        onChangeView={props.onChangeView}
        selectedDate={props.selectedDate}
        onChangeDate={props.onChangeDate}
      />
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>L</div>
        <div>M</div>
        <div>M</div>
        <div>G</div>
        <div>V</div>
        <div>S</div>
        <div>D</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {days?.length > 0 &&
          days.map((day, dayIdx) => {
            return (
              <div
                key={day.date}
                className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
                <button
                  onClick={() => props.onChangeDate(day.date)}
                  type="button"
                  className={classNames(
                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full',

                    {
                      'text-gray-500': !isSameMonth(day.date, props.selectedDate),
                      'bg-indigo-500 text-white': isSameDay(day.date, props.selectedDate),
                      'bg-gray-300 text-black': isSameDay(day.date, new Date()),
                    },
                  )}>
                  <time dateTime={day.date}>
                    {format(day.date, 'yyyy-MM-dd').split('-').pop().replace(/^0/, '')}
                  </time>
                </button>
              </div>
            );
          })}
      </div>
      <section className="mt-12">
        <ModalNuovoAppuntamento
          refetch={props.refetch}
          resourceId={props.selectedResource}
          date={props.selectedDate}
        />
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
          {events?.length > 0 &&
            events.map((event) => {
              return (
                <ModalAppuntamento key={event._id} event={event} refetch={props.refetch}>
                  <li className="flex w-full p-8">
                    <div className="flex-1">
                      <div className="flex flex-col">
                        <p className="text-base flex-none sm:ml-6">
                          <time dateTime="2022-01-13T14:30"> {getFrom(event.startDate)}</time> -{' '}
                          <time dateTime="2022-01-13T16:30">
                            {getTo(event.startDate, event.duration)}
                          </time>
                        </p>
                        <p
                          className={classNames(
                            'text-xl mt-2 flex-auto font-regular text-gray-900 sm:mt-0',
                            {
                              'text-green-500': event.type === 'MANUTENZIONE',
                              'text-red-500': event.type === 'GUASTO',
                              'text-blue-500': event.type === 'ALTRO',
                              'text-yellow-500': event.type === 'COLLAUDO',
                            },
                          )}>
                          {event.title}
                        </p>
                      </div>

                      {event.notes !== '' && (
                        <p className="text-base w-full mt-2 p-2 rounded bg-gray-100 text-gray-900">
                          {event.notes}
                        </p>
                      )}
                    </div>
                  </li>
                </ModalAppuntamento>
              );
            })}
        </ol>
      </section>
    </div>
  );
}
