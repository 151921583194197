import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

function Input({
  label = 'Label',
  error,
  name,
  colspan = 6,
  type = 'text',
  classNameExtensions,
  ...props
}) {
  return (
    <div className={`col-span-6 sm:col-span-3 lg:col-span-${colspan}`}>
      <label htmlFor={name} className="block text-sm font-medium text-black">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          type={type}
          id={name}
          name={name}
          className={classNames(
            'h-10 block w-full pr-2 text-black focus:outline-none sm:text-sm rounded-md',
            {
              'border-red-500 focus:ring-red-500 focus:border-red-500': error,
              'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500': !error,
              [`${classNameExtensions}`]: true,
            },
          )}
          {...props}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-400" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
}

export default Input;
