import classNames from 'classnames';
import { format, isSameDay, isSameMonth, isSameWeek } from 'date-fns';

import Event from './event';

import ModalGiorno from '../ModalGiorno';

const MoreEvents = (props) => {
  return props.numberOfEvents > props.limit ? (
    <p className="mt-2 font-light text-gray-800 text-sm">{`+ ${
      props.numberOfEvents - props.limit
    } ${props.numberOfEvents === 1 ? 'altro' : 'altri'}`}</p>
  ) : null;
};

const Day = (props) => {
  return (
    <ModalGiorno
      day={props.day}
      refetch={props.refetch}
      resourceId={props.selectedResource}
      key={props.key}>
      <div
        className={classNames('h-full border-2', {
          'bg-gray-100':
            (props.view === 'month' &&
              !isSameMonth(props.day.date, new Date(props.selectedDate))) ||
            (props.view === 'week' && !isSameWeek(props.day.date, new Date(props.selectedDate))),
          'font-medium':
            (props.view === 'month' && isSameMonth(props.day.date, new Date(props.selectedDate))) ||
            isSameWeek(props.day.date, new Date(props.selectedDate)),
        })}>
        <p
          className={classNames('flex items-center px-2 py-1 gap-2', {
            'bg-indigo-500 text-white font-medium': isSameDay(props.day.date, new Date()),
          })}>
          <span>{format(props.day.date, 'dd')}</span>
          {props.view === 'week' ? <span>{props.weekDays[props.day.date.getUTCDay()]}</span> : null}
        </p>
        <div className="px-2 mt-3">
          {props.day.events.slice(0, props.eventsLimit).map((event, idx) => (
            <Event key={idx} event={event} />
          ))}

          <MoreEvents numberOfEvents={props.day.events.length} limit={props.eventsLimit} />
        </div>
      </div>
    </ModalGiorno>
  );
};
export default Day;
