import {
  differenceInCalendarDays,
  isSameDay,
  isAfter,
  isBefore,
  endOfWeek,
  startOfWeek,
  addDays,
} from 'date-fns';

import Day from './day';

import Header from './header/weekHeader';

const Week = (props) => {
  const utcDate = new Date(props.selectedDate.toUTCString());

  const start = addDays(startOfWeek(utcDate), 1);
  const end = addDays(endOfWeek(utcDate), 1);

  const difference = differenceInCalendarDays(end, start);

  let days = [];

  for (let day = 0; day <= difference; day++) {
    days.push({
      date: addDays(start, day),
      isCurrentMonth: true,
      events: [],
    });
  }

  const resourcesWithEvents = props.resources.map((resource) => {
    const resourceDays = days.map((day) => {
      const eventsThisDay = props.events.filter(
        (event) =>
          isSameDay(new Date(event.startDate), day.date) && resource._id === event.resourceId,
      );

      const eventsOrdered = eventsThisDay.sort((eventA, eventB) => {
        const dateEventA = new Date(eventA.startDate);
        const dateEventB = new Date(eventB.startDate);

        if (isBefore(dateEventA, dateEventB)) return -1;
        if (isAfter(dateEventA, dateEventB)) return 1;
        return 0;
      });

      const dayWithEvents = {
        date: day.date,
        isCurrentMonth: day.isCurrentMonth,
        events: eventsOrdered,
      };

      return dayWithEvents;
    });
    return { ...resource, days: resourceDays };
  });

  const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

  return (
    <div className="h-full">
      <Header
        selectedDate={props.selectedDate}
        onChangeDate={props.onChangeDate}
        onChangeView={props.onChangeView}
      />
      <div className="mx-6 pb-8">
        {resourcesWithEvents.map((resource) => {
          return (
            <div className="flex flex-col h-full mb-8">
              <h2 className="text-xl font-medium">{`${resource.nome} ${resource.cognome}`}</h2>

              <div className="grid grid-cols-7">
                {resource.days.map((day) => {
                  return (
                    <Day
                      selectedResource={resource._id}
                      weekDays={weekDays}
                      selectedDate={props.selectedDate}
                      day={day}
                      eventsLimit={props.eventsLimit || 5}
                      view={props.view}
                      refetch={props.refetch}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Week;
