// main
import React, { useState } from 'react';

// routing
import { useHistory } from 'react-router-dom';

// api request
import axios from 'axios';

// style
import { Label, Input, Button, Error, Select } from '../../../styles/App';
import {
  ModalContainer,
  CloseModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../styles/Modal';

// context
import { useAuth } from '../../../Context/AuthContext';
import { useLoading } from '../../../Context/LoadingContext';

// form
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './validations/validationSchema';

// other
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Plus } from 'styled-icons/feather';

// Component
export default function CreaLavoratoreModal() {
  const history = useHistory();

  const { showLoading, hideLoading } = useLoading();

  const [isOpen, setIsOpen] = useState(false);

  const hideModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const { token } = useAuth();

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnSubmit: true,
    validationSchema,

    onSubmit: async (values) => {
      showLoading();
      try {
        const {
          data: { id_utente, message },
        } = await axios.post(`${process.env.REACT_APP_API_HOST}/api/lavoratori`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success(message);
        history.push(`/lavoratori/${id_utente}`);
      } catch (error) {
        const {
          response: {
            data: { message },
          },
        } = error;
        toast.error(message);
      } finally {
        hideLoading();
      }
    },
  });

  const { nome, cognome, email, tipo } = values;
  return (
    <>
      <Button onClick={showModal}>
        <Plus />
        Nuovo Lavoratore
      </Button>
      <Modal isOpen={isOpen} className="overlayModal">
        <ModalContainer>
          <ModalHeader>
            <h2>Crea nuovo lavoratore</h2>
            <CloseModal onClick={hideModal}>X</CloseModal>
          </ModalHeader>

          <ModalBody onSubmit={handleSubmit}>
            <Label>
              Tipo lavoratore:
              <Select name="tipo" value={tipo} onChange={handleChange}>
                <option />
                <option value="impiegato">Impiegato</option>
                <option value="tecnico">Tecnico</option>
              </Select>
              <Error>{errors?.tipo ? errors.tipo : null}</Error>
            </Label>
            <Label className={errors?.nome ? 'error' : null}>
              Nome:
              <Input type="text" name="nome" value={nome} onChange={handleChange} />
              <Error>{errors?.nome ? errors.nome : null}</Error>
            </Label>
            <Label className={errors?.cognome ? 'error' : null}>
              Cognome:
              <Input type="text" name="cognome" value={cognome} onChange={handleChange} />
              <Error>{errors?.cognome ? errors.cognome : null}</Error>
            </Label>
            <Label className={errors?.email ? 'error' : null}>
              E-mail:
              <Input type="text" name="email" value={email} onChange={handleChange} />
              <Error>{errors?.email ? errors.email : null}</Error>
            </Label>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" onClick={handleSubmit}>
              Crea lavoratore
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  );
}

Modal.setAppElement('#root');
