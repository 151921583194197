import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

import { addMonths, startOfMonth } from 'date-fns';
import { endOfMonth, subMonths } from 'date-fns/esm';
import { useAuth } from '../../../../../Context/AuthContext';

const Header = (props) => {
  const { user } = useAuth();

  const onNextMonthClick = () => {
    props.onChangeDate(addMonths(endOfMonth(props.selectedDate), 1));
  };

  const onPreviousMonthClick = () => {
    props.onChangeDate(subMonths(startOfMonth(props.selectedDate), 1));
  };

  const onTodayClick = () => {
    props.onChangeDate(new Date(new Date().toUTCString()));
  };

  return (
    <div className="flex justify-between p-6">
      <h1 className="font-bold text-xl">
        {props.selectedDate.toLocaleString('default', { month: 'long' }).toUpperCase()}{' '}
        {props.selectedDate.getFullYear()}
      </h1>

      <div className="flex">
        {user?.tipo !== 'tecnico' && (
          <button
            onClick={() => {
              props.onChangeView('week');
            }}
            type="button"
            className="mr-6 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Settimanale
          </button>
        )}
        <div className="gap-2 flex items-center">
          <button onClick={onPreviousMonthClick} type="button" className="w-10 p-2">
            <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          </button>
          <button onClick={onTodayClick} type="button">
            Oggi
          </button>
          <button onClick={onNextMonthClick} type="button">
            <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
