import { format } from 'date-fns';
import classNames from 'classnames';

const Event = (props) => {
  return (
    <div title={props.event.title} className={classNames('flex gap-1 items-center')}>
      <p className="text-black font-normal text-sm">
        {format(new Date(props.event.startDate), 'HH:mm')}
      </p>
      <p
        className={classNames('truncate text-black font-semibold text-sm p-1 rounded-md', {
          'text-green-500': props.event.type === 'MANUTENZIONE',
          'text-red-500': props.event.type === 'GUASTO',
          'text-blue-500': props.event.type === 'ALTRO',
          'text-yellow-500': props.event.type === 'COLLAUDO',
        })}>
        {props.event.title}
      </p>
    </div>
  );
};
export default Event;
