import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import axios from 'axios';

import { useAuth } from '../../Context/AuthContext';
import { useLoading } from '../../Context/LoadingContext';

import { initialValues, validationSchema } from './validations/validationSchema';

import { Page, Container, ContainerHeader, ContainerBody, ContainerFooter } from '../../Auth/style';
import { Label, Input, Button, Error } from '../../styles/App';

export default function FirstLogin() {
  const history = useHistory();
  const { showLoading, hideLoading } = useLoading();

  const {
    user: { _id },
  } = useAuth();
  const { token } = useAuth();

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { _id, ...initialValues },
    validateOnChange: true,
    validateOnSubmit: true,
    validationSchema,
    onSubmit: async (v) => {
      showLoading();

      try {
        axios.post(`${process.env.REACT_APP_API_HOST}/api/lavoratori/firstlogin`, v, {
          headers: { Authorization: `Bearer ${token}` },
        });

        toast.success('Password impostata');
        history.push('/logout');
      } catch (e) {
        const {
          resonse: {
            data: { message },
          },
        } = e;

        toast.error(message);
      } finally {
        hideLoading();
      }
    },
  });

  const { password, confirmPassword } = values;

  return (
    <Page>
      <Container>
        <ContainerHeader>
          <h1>Imposta password</h1>
        </ContainerHeader>

        <ContainerBody onSubmit={handleSubmit}>
          <Label className={errors?.password ? 'error' : null}>
            Password:
            <Input type="password" name="password" value={password} onChange={handleChange} />
            <Error>{errors?.password ? errors.password : null}</Error>
          </Label>
          <Label className={errors?.confirmPassword ? 'error' : null}>
            Conferma password:
            <Input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
            />
            <Error>{errors?.confirmPassword ? errors.confirmPassword : null}</Error>
          </Label>
          <ContainerFooter>
            <Button type="submit">Attiva l'account</Button>
          </ContainerFooter>
        </ContainerBody>
      </Container>
    </Page>
  );
}
