// main
import React, { useState } from 'react';

import axios from 'axios';

import Layout from '../../Layout';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import { Page, Text, View, Document, StyleSheet, PDFViewer, Table } from '@react-pdf/renderer';

import Input from '../../components/forms/input';
import { useFormik } from 'formik';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 16,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },

  intro: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  periodo: {
    fontSize: '8px',
    width: '100%',
    textAlign: 'left',
  },

  total: {
    fontSize: '8px',
    width: '100%',
    textAlign: 'right',
  },
  row: {
    paddingVertical: 8,
    width: '100%',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    wordWrap: 'break-all',
    alignItems: 'center',
    borderBottom: '1px solid gray',
  },
  cell: {
    flex: 1,
  },
});

// Create Document Component
const MyDocument = ({ chiamate, periodo, totale }) => (
  <Document>
    <Page orientation="landscape" style={styles.page}>
      <View style={styles.intro}>
        <View style={styles.periodo}>
          <View>
            <Text style={styles.periodoText}>
              {periodo.mese_controllo} / {periodo.prossimo_controllo}
            </Text>
          </View>
        </View>
        <View style={styles.total}>
          <Text style={styles.totalText}>Totale: {totale} chiamate</Text>
        </View>
      </View>
      <View style={[styles.row, { backgroundColor: '#e0e0e0' }]}>
        <Text style={styles.cell}>RESPONSABILE</Text>
        <Text style={styles.cell}>COMUNE</Text>
        <Text style={styles.cell}>ULTIMO CONTROLLO</Text>
        <Text style={styles.cell}>ULTIMO FUMI</Text>
        <Text style={styles.cell}>FC</Text>
      </View>
      {chiamate.map((chiamata) => (
        <View
          style={
            chiamata.generatore.formula_comfort === ''
              ? styles.row
              : [styles.row, { backgroundColor: '#eeeeee' }]
          }>
          <Text style={styles.cell}>
            {chiamata.responsabile.cognome || ''} {chiamata.responsabile.nome || ''}
          </Text>

          <Text style={styles.cell}>{chiamata.ubicazione.comune || ''}</Text>

          <Text style={styles.cell}>
            {chiamata.manutenzioni.ultimo_controllo ? chiamata.manutenzioni.ultimo_controllo : ''}
          </Text>
          <Text style={styles.cell}>
            {chiamata.manutenzioni.ultimo_fumi ? chiamata.manutenzioni.ultimo_fumi : ''}
          </Text>
          <Text style={styles.cell}>
            {chiamata.generatore.formula_comfort ? chiamata.generatore.formula_comfort : ''}
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

const style = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  table: {},
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '2px solid red',
  },
  cell: {},
});
const PDFChiamate = ({ chiamate, periodo, totale }) => (
  <Document
    title={`CHIAMATE ${
      periodo.mese_controllo && periodo.prossimo_controllo
        ? `${periodo.mese_controllo} ${periodo.prossimo_controllo}`
        : ''
    }`}>
    <Page orientation="landscape">
      <View debug wrap style={style.header}>
        <Text>
          {periodo.mese_controllo && periodo.prossimo_controllo
            ? `${periodo.mese_controllo} / ${periodo.prossimo_controllo}`
            : null}
        </Text>
        <Text>totale: {totale}</Text>
      </View>
      <View style={style.table}>
        {/* table */}
        <View>
          {/* table header */}
          <View style={style.row}>
            {/* ROW */}
            <View debug>
              <Text style={style.cell} debug>
                RESPONSABILE
              </Text>
            </View>
            <View debug>
              <Text style={style.cell} debug>
                COMUNE
              </Text>
            </View>
            <View debug>
              <Text style={style.cell} debug>
                ULTIMO CONTROLLO
              </Text>
            </View>
            <View debug>
              <Text style={style.cell} debug>
                ULTIMO FUMI
              </Text>
            </View>
            <View debug>
              <Text style={style.cell} debug>
                FC
              </Text>
            </View>
          </View>
        </View>
        <View>
          {/* table body */}
          {chiamate.map((chiamata) => {
            return (
              <View style={style.row}>
                {/* row */}
                <Text>
                  {chiamata.responsabile.cognome} {chiamata.responsabile.nome}
                </Text>
                <Text>{chiamata.ubicazione.comune}</Text>
                <Text>{chiamata.manutenzioni.ultimo_controllo}</Text>
                <Text>{chiamata.manutenzioni.ultimo_fumi}</Text>
                <Text>{chiamata.generatore.formula_comfort}</Text>
              </View>
            );
          })}
        </View>
      </View>

      {/* {chiamate.map((chiamata) => (
        <View
          style={
            chiamata.generatore.formula_comfort === ''
              ? styles.row
              : [styles.row, { backgroundColor: '#EEE' }]
          }>
          <Text>{chiamata.manutenzioni.mese_controllo || ''}</Text>
          <Text>{chiamata.responsabile.cognome || ''}</Text>
          <Text>{chiamata.responsabile.nome || ''}</Text>

          <Text>{chiamata.ubicazione.comune || ''}</Text>

          <Text>
            {chiamata.manutenzioni.ultimo_controllo ? chiamata.manutenzioni.ultimo_controllo : ''}
          </Text>
          <Text>{chiamata.manutenzioni.ultimo_fumi ? chiamata.manutenzioni.ultimo_fumi : ''}</Text>
          <Text>
            {chiamata.generatore.formula_comfort ? chiamata.generatore.formula_comfort : ''}
          </Text>

          <Text>{chiamata.generatore.annotazioni || ''}</Text>
        </View>
      ))} */}
    </Page>
  </Document>
);

const Chiamate = () => {
  const { showLoading, hideLoading } = useLoading();
  const { token } = useAuth();

  const [periodo, setPeriodo] = useState({});

  const [chiamate, setChiamate] = useState([]);
  const [total, setTotal] = useState([]);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: { prossimo_controllo: '', mese_controllo: '' },
    onSubmit: (values) => {
      showLoading();

      axios
        .get(`${process.env.REACT_APP_API_HOST}/api/chiamate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { ...values },
        })
        .then(({ data }) => {
          const { chiamate, total } = data;
          setChiamate(chiamate);
          setTotal(total);
          setPeriodo(values);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          hideLoading();
        });
    },
  });

  return (
    <Layout title="Lista chiamate">
      <div className="p-4">
        <div className="flex gap-2 items-end">
          <div className="flex-1">
            <Input
              type="text"
              value={values.mese_controllo}
              label="MESE"
              placeholder="MESE CONTROLLO"
              name="mese_controllo"
              onChange={handleChange}
            />
          </div>

          <div className="flex-1">
            <Input
              type="text"
              label="ANNO"
              value={values.prossimo_controllo}
              placeholder="Prossimo controllo"
              name="prossimo_controllo"
              onChange={handleChange}
            />
          </div>
          <div>
            <button
              className="inline-flex items-center px-6 h-10 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSubmit}>
              Trova chiamate
            </button>
          </div>
        </div>
      </div>
      <PDFViewer style={{ height: '100%' }}>
        <MyDocument chiamate={chiamate} periodo={periodo} totale={total} />
      </PDFViewer>
    </Layout>
  );
};

export default Chiamate;
