import React from 'react';
import ReactPagination from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const Pagination = ({ total, offset, limit, setQuery }) => {
  const pageCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;
  const showingFrom = offset + 1;
  const showingTo = total < offset + limit ? total : offset + limit;

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      {/* Mobile */}
      <div className="flex-1 flex justify-between sm:hidden items-center">
        <button
          onClick={() => setQuery({ offset: offset - limit })}
          disabled={offset < 1}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Indietro
        </button>
        <p className="text-sm text-gray-700">
          da <span className="font-medium">{showingFrom}</span> a{' '}
          <span className="font-medium">{showingTo}</span> di{' '}
          <span className="font-medium">{total}</span> risultati
        </p>
        <button
          onClick={() => setQuery({ offset: offset + limit })}
          disabled={currentPage >= pageCount}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Avanti
        </button>
      </div>

      {/* Desktop */}
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            da <span className="font-medium">{showingFrom}</span> a{' '}
            <span className="font-medium">{showingTo}</span> di{' '}
            <span className="font-medium">{total}</span> risultati
          </p>
        </div>
        <div>
          <ReactPagination
            onPageChange={({ selected }) => setQuery({ offset: selected * limit })}
            activeLinkClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
            previousClassName="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            nextClassName="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            containerClassName="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            pageLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
            forcePage={offset / limit}
            pageCount={pageCount}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
            nextLabel={<ChevronRightIcon className="h-5 w-5" />}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
