import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import axios from 'axios';

import { useAuth } from '../../Context/AuthContext';
import { useLoading } from '../../Context/LoadingContext';

import validationSchema from './validation';

import {
  Page,
  Container,
  ContainerHeader,
  ContainerBody,
  Label,
  Input,
  Error,
} from '../../Auth/style';

import { ChevronRight } from 'styled-icons/entypo';

export default function OnBoarding() {
  const { token } = useAuth();
  const history = useHistory();
  const { showLoading, hideLoading } = useLoading();

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      ragione_sociale: '',
      partita_iva: '',
      responsabile: {
        nome: '',
        cognome: '',
        codice_fiscale: '',
      },
    },
    validateOnChange: true,
    validateOnSubmit: true,
    validationSchema,
    onSubmit: async (v) => {
      showLoading();
      try {
        await axios.post(`${process.env.REACT_APP_API_HOST}/api/onboarding`, v, {
          headers: { Authorization: `Bearer ${token}` },
        });

        toast.info('Azienda registrata correttamente');
        history.push('/logout');
        history.push('/alert/companyConfirmed');
      } catch (e) {
        toast.error(e.response.data.message);
      }

      hideLoading();
    },
  });

  const { ragione_sociale, partita_iva, responsabile } = values;

  return (
    <Page>
      <Container>
        <ContainerHeader>
          <h1>Completa la registrazione</h1>
          <p>Completa la registrazione inserendo i dati richiesti</p>
        </ContainerHeader>

        <h3>Azienda</h3>
        <ContainerBody onSubmit={handleSubmit}>
          <Label className={errors?.ragione_sociale ? 'error' : null}>
            <ChevronRight />
            <Input
              type="text"
              name="ragione_sociale"
              value={ragione_sociale}
              onChange={handleChange}
              placeholder="Inserisci la ragione sociale"
            />
            <Error>{errors?.ragione_sociale ? errors.ragione_sociale : null}</Error>
          </Label>
          <Label className={errors?.partita_iva ? 'error' : null}>
            <ChevronRight />

            <Input
              type="text"
              name="partita_iva"
              value={partita_iva}
              onChange={handleChange}
              placeholder="Inserisci la partita iva"
            />
            <Error>{errors?.partita_iva ? errors.partita_iva : null}</Error>
          </Label>
          <br />
          <h3>Legale rappresentante</h3>
          <Label className={errors?.responsabile?.nome ? 'error' : null}>
            <ChevronRight />

            <Input
              type="text"
              name="responsabile.nome"
              value={responsabile?.nome}
              onChange={handleChange}
              placeholder="Inserisci nome"
            />
            <Error>{errors?.responsabile?.nome ? errors.responsabile?.nome : null}</Error>
          </Label>
          <Label className={errors?.responsabile?.cognome ? 'error' : null}>
            <ChevronRight />

            <Input
              type="text"
              name="responsabile.cognome"
              value={responsabile?.cognome}
              onChange={handleChange}
              placeholder="Inserisci cognome"
            />
            <Error>{errors?.responsabile?.cognome ? errors.responsabile?.cognome : null}</Error>
          </Label>
          <Label className={errors?.responsabile?.codice_fiscale ? 'error' : null}>
            <ChevronRight />

            <Input
              type="text"
              name="responsabile.codice_fiscale"
              value={responsabile?.codice_fiscale}
              onChange={handleChange}
              placeholder="Codice fiscale"
            />
            <Error>
              {errors?.responsabile?.codice_fiscale ? errors.responsabile?.codice_fiscale : null}
            </Error>
          </Label>
        </ContainerBody>
      </Container>
    </Page>
  );
}
