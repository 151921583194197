import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Page = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: #ffffff;
  padding: 3rem;
  margin-bottom: 2rem;
  border-radius: 0.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 300px;
`;

export const ContainerHeader = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

export const ContainerBody = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const CustomLink = styled(Link)`
  font-size: 14px;
`;

export const Label = styled.label`
  min-width: 400px;

  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--title);

  border: 1px solid var(--downlight);
  border-radius: 0.5rem;

  height: 50px;
  padding: 0 1rem;

  margin-bottom: 1rem;

  &:hover,
  &:focus-within {
    border-color: var(--title);
  }

  & > svg {
    width: 20px;
  }

  & > input {
    flex: 1;
  }

  &.error {
    color: var(--highlight);
    & > input {
      border-color: var(--highlight);
    }
  }
`;

export const Error = styled.span`
  font-size: 12px;
`;

export const Input = styled.input`
  font-size: 16px;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;

  margin-top: 0.25rem;

  border: none;
  border-radius: 0.25rem;

  outline: none;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: none;

  cursor: pointer;

  height: 50px;

  flex: 1;

  border-radius: 5px;

  background-color: var(--complem);
  color: #fff;

  &.delete {
    background-color: #e75a7c;
  }

  &:hover {
    background-color: var(--title);
  }
`;
