import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PaperClipIcon } from '@heroicons/react/solid';

import { toast } from 'react-toastify';
import axios from 'axios';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import Layout from '../../Layout';

export default function Documenti() {
  const { showLoading, hideLoading } = useLoading();
  const { token } = useAuth();
  const [documenti, setDocumenti] = useState({});

  const { id } = useParams();

  console.log(documenti);

  useEffect(() => {
    showLoading();
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/pdf/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setDocumenti(data.pdf);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        hideLoading();
      });
  }, []);

  return (
    <Layout title={`Documenti`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {documenti.prodottoFormattato}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {documenti.codiceAssistenza}
            {documenti.daMatricola ? `(da matricola ${documenti.daMatricola})` : null}
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Esploso</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {documenti.linkEsploso ? (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href={documenti.linkEsploso}>
                    Apri esploso
                  </a>
                ) : (
                  'Non disponibile'
                )}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Libretto istruzioni</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {documenti.linkLibrettoIstruzioni ? (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href={documenti.linkLibrettoIstruzioni}>
                    Apri libretto istruzioni
                  </a>
                ) : (
                  'Non disponibile'
                )}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Pannello di controllo</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {documenti.linkPanelloDiControllo ? (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href={documenti.linkPanelloDiControllo}>
                    Apri pannello di controllo
                  </a>
                ) : (
                  'Non disponibile'
                )}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Scheda tecnica</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {documenti.linkSchedaTecnica ? (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href={documenti.linkSchedaTecnica}>
                    Apri scheda tecnica
                  </a>
                ) : (
                  'Non disponibile'
                )}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Schema elettrico</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {documenti.linkSchemaElettrico ? (
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href={documenti.linkSchemaElettrico}>
                    Apri schema elettrico
                  </a>
                ) : (
                  'Non disponibile'
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </Layout>
  );
}
