const Weekdays = (props) => {
  return (
    <div className="grid grid-cols-7">
      {props.weekDays.map((weekDay) => (
        <div className="p-3">
          <p>{weekDay}</p>
        </div>
      ))}
    </div>
  );
};

export default Weekdays;
