import React from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

function Select({ label = 'Label', error, name, options, colspan = 6, classNameExtend, ...props }) {
  return (
    <div className={`col-span-6 sm:col-span-3 lg:col-span-${colspan}`}>
      <label htmlFor={name} className="block text-sm font-medium text-black">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <select
          id={name}
          name={name}
          className={classNames(
            'h-10 block w-full pr-2 text-text focus:outline-none sm:text-sm rounded-md',
            {
              'border-red-500 focus:ring-red-500 focus:border-red-500': error,
              'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500': !error,
            },
          )}
          {...props}>
          <option value="" />
          {options.map((option) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-500" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
}

export default Select;
