import React from 'react';

import { Route, useHistory, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';

import { useAuth } from '../Context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isLogged, user, logout } = useAuth();

  return (
    <Route
      render={(props) => {
        if (isLogged) {
          if (user.tipo !== 'admin') {
            if (user.isActive) {
              if (!user.firstLogin && pathname !== '/firstlogin') return <Component {...props} />;
              if (user.firstLogin && pathname === '/firstlogin') return <Component {...props} />;
              if (user.firstLogin && pathname !== '/firstlogin') return history.push('/firstlogin');
              if (!user.firstLogin && pathname === '/firstlogin') return history.push('/');
            } else {
              toast.info("Controlla la tua casella di posta e attiva l'account");
              history.push('/');
            }
          } else history.push('/');
        } else history.push('/');
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;
