import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

function TextArea({ label = 'Label', name, colspan = 6, error, ...props }) {
  return (
    <div className={`col-span-6 sm:col-span-3 lg:col-span-${colspan}`}>
      <label htmlFor={name} className="block text-sm font-medium text-black">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          rows={5}
          id={name}
          name={name}
          className={classNames(
            'block w-full pr-2 text-text focus:outline-none sm:text-sm rounded-md',
            {
              'border-red-500 focus:ring-red focus:border-red': error,
              'border-gray-300 focus:ring-light-blue focus:border-light-blue': !error,
            },
          )}
          {...props}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
}

export default TextArea;
