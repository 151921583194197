import * as Yup from 'yup';

export const impiantoInitialValues = {
  responsabile: {
    cognome: '',
    nome: '',
    codice_fiscale: '',
    ragione_sociale: '',
    annotazioni: '',
  },
  ubicazione: {
    provincia: '',
    comune: '',
    localita: '',
    indirizzo: '',
    civico: '',
    piano: '',
    interno: '',
    scala: '',
    annotazioni: '',
  },
  contatti: {
    casa: '',
    mobile: '',
    email: '',
    chiamate: '',
  },
  curit: {
    targa: '',
  },
  generatore: {
    modello: '',
    matricola: '',
    collaudo: '',
    formula_comfort: '',
    scadenza_garanzia: '',
    idraulico: '',
    dico: '',
    causale_installazione: '',
    annotazioni: '',
    storico: '',
  },
  manutenzioni: {
    interventi: '',
    ultimo_controllo: '',
    ultimo_fumi: '',
    prossimo_controllo: '',
    mese_controllo: '',
    annotazioni: '',
  },
};
const REGEX_CONTATTI_TELEFONICI = /^((^| - )[0-9 ]+( \([\w ]+\))?)+$/gim;

const REGEX_FC = /^FC [0-9]{1,2}(\+[0-9]){0,2} EURO [0-9]{2,3}$/i;
const REGEX_CF_PIVA =
  /^((?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z])|[0-9]{9}$/i;

const REGEX_TARGA_IMPIANTO = /^([0-9A-Z]{5}[0-9]{9}03)$|^([0-9]{12} \([0-9a-z]{9}\))$/g;
const REGEX_DATA = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/i;

export const impiantoValidationSchema = Yup.object().shape({
  responsabile: Yup.object().shape({
    nome: Yup.string().required('Campo richiesto'),
    cognome: Yup.string().required('Campo richiesto'),
    codice_fiscale: Yup.string().matches(REGEX_CF_PIVA, 'Codice fiscale o p.iva non valido'),
    ragione_sociale: Yup.string(),
    annotazioni: Yup.string(),
  }),
  ubicazione: Yup.object().shape({
    provincia: Yup.string().required('Campo richiesto'),
    comune: Yup.string().required('Campo richiesto'),
    localita: Yup.string(),
    indirizzo: Yup.string().required('Campo richiesto'),
    civico: Yup.string(),
    piano: Yup.string(),
    interno: Yup.string(),
    scala: Yup.string(),
    annotazioni: Yup.string(),
  }),
  contatti: Yup.object().shape({
    casa: Yup.string().matches(
      REGEX_CONTATTI_TELEFONICI,
      "il formato deve essere 'numero (annotazioni) - numero (annotazioni) - ...'",
    ),
    mobile: Yup.string().matches(
      REGEX_CONTATTI_TELEFONICI,
      "il formato deve essere 'numero (annotazioni) - numero (annotazioni) - ...'",
    ),
    email: Yup.string().email('Inserire una mail valida'),
    chiamate: Yup.string(),
  }),
  curit: Yup.object().shape({
    targa: Yup.string().matches(
      REGEX_TARGA_IMPIANTO,
      'Inserire "targa" CURIT valida o "codice (chiave)" CIRCE validi',
    ),
  }),
  generatore: Yup.object().shape({
    modello: Yup.string().required('Campo richiesto'),
    matricola: Yup.string().required('Campo richiesto'),
    collaudo: Yup.string().matches(REGEX_DATA, 'Inserire una data valida'),
    formula_comfort: Yup.string().matches(
      REGEX_FC,
      "Il formato deve essere 'FC anni (5+3+2) EURO prezzo (100)]'",
    ),
    scadenza_garanzia: Yup.string().matches(REGEX_DATA, 'Inserire una data valida'),
    idraulico: Yup.string(),
    dico: Yup.string(),
    interventi: Yup.string(),
    causale_installazione: Yup.string(),
    annotazioni: Yup.string(),
    storico: Yup.string(),
  }),
  manutenzioni: Yup.object().shape({
    ultimo_controllo: Yup.string().matches(REGEX_DATA, 'Inserire una data valida'),
    ultimo_fumi: Yup.string().matches(REGEX_DATA, 'Inserire una data valida'),
    prossimo_controllo: Yup.string(),
    mese_controllo: Yup.string(),
    annotazioni: Yup.string(),
  }),
});
