import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import PublicRoute from './Routes/PublicRoute';
import PrivateRoute from './Routes/PrivateRoute';
import AdminRoute from './Routes/AdminRoute';

import Login from './Auth/Login';
import Logout from './Auth/Logout';
import Register from './Auth/Register';
import Confirm from './Auth/Confirm';
import ForgotPassword from './Auth/ForgotPassword';
import Alert from './Auth/Alert';

import OnBoarding from './Pages/OnBoarding';
import Lavoratori from './Pages/Lavoratori';
import Lavoratore from './Pages/Lavoratore';
//import Magazzini from './Pages/Magazzini';
import Stock from './Pages/Stock';
import Calendario from './Pages/Calendario';

import FirstLogin from './Pages/FirstLogin';
import Dashboard from './Pages/Dashboard';
import Impianti from './Pages/Impianti';
import Impianto from './Pages/Impianto';
import Ricambi from './Pages/Ricambi';
import Chiamate from './Pages/Chiamate';
import Documentazione from './Pages/Documentazione';
import Documenti from './Pages/Documenti';
import NotFound from './Pages/404';

export default function App() {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        {/* Routing pubblico */}
        <PublicRoute exact path="/login" component={Login} />
        {/* <PublicRoute exact path="/register" component={Register} /> */}
        {/* <PublicRoute exact path="/confirm/:confirmCode" component={Confirm} /> */}
        {/* <PublicRoute path="/forgotpassword" component={ForgotPassword} /> */}
        <PublicRoute path="/alert/:alertType" component={Alert} />

        {/* Routing privato */}
        <PrivateRoute exact path="/firstlogin" component={FirstLogin} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/impianti" component={Impianti} />
        <PrivateRoute exact path="/impianti/:id" component={Impianto} />
        <PrivateRoute exact path="/chiamate" component={Chiamate} />
        <PrivateRoute exact path="/documentazione" component={Documentazione} />
        <PrivateRoute exact path="/documentazione/:id" component={Documenti} />
        <PrivateRoute exact path="/calendario" component={Calendario} />

        {/* <PrivateRoute exact path="/appuntamenti" component={Appuntamenti} /> */}
        <PrivateRoute exact path="/listino" component={Ricambi} />
        {/* <PrivateRoute exact path="/sospeso" component={Sospeso} />  */}

        {/* Routing admin */}
        <AdminRoute exact path="/onboarding" component={OnBoarding} />
        <AdminRoute exact path="/lavoratori" component={Lavoratori} />
        <AdminRoute exact path="/lavoratori/:idLavoratore" component={Lavoratore} />
        {/* <AdminRoute exact path="/magazzini" component={Magazzini} /> */}

        {/* Altri routing */}
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/404" component={NotFound} />

        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </QueryParamProvider>
  );
}
