import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { CheckIcon } from '@heroicons/react/solid';

import { toast } from 'react-toastify';
import axios from 'axios';

import { useLoading } from '../../Context/LoadingContext';
import { useAuth } from '../../Context/AuthContext';

import Layout from '../../Layout';

import { impiantoInitialValues, impiantoValidationSchema } from './formValues';

import Input from '../../components/forms/input';
import TextArea from '../../components/forms/textarea';
import Select from '../../components/forms/select';

const Toolbar = ({ submitForm }) => {
  return (
    <div className="flex justify-end w-full">
      <button
        onClick={submitForm}
        className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        Salva modifiche
      </button>
    </div>
  );
};

export default function Impianto() {
  const { showLoading, hideLoading } = useLoading();
  const { token } = useAuth();

  const { id } = useParams();
  const { values, errors, handleChange, handleSubmit, setValues, submitForm } = useFormik({
    initialValues: impiantoInitialValues,
    validationSchema: impiantoValidationSchema,
    validateOnChange: true,
    validateOnSubmit: true,
    onSubmit: (values) => {
      showLoading();
      axios
        .patch(`${process.env.REACT_APP_API_HOST}/api/impianti/${id}`, values, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => {
          const { status, data } = r;
          if (status === 200) return toast.success(data.message);
          return toast.warning(data.message);
        })
        .catch((e) => console.log(e))
        .finally(hideLoading());
    },
  });

  useEffect(() => console.log(errors), [errors]);

  useEffect(() => {
    showLoading();
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/impianti/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setValues(data);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        hideLoading();
      });
  }, []);

  return (
    <Layout
      title={`${values.responsabile.nome} ${values.responsabile.cognome}`}
      toolbar={<Toolbar submitForm={submitForm} />}>
      <form className="grid xl:grid-cols-3 bg-white h-full">
        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Responsabile</h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Input
              colspan={3}
              label="NOME"
              name="responsabile.nome"
              value={values.responsabile.nome}
              onChange={handleChange}
              error={errors.responsabile?.nome}
            />

            <Input
              colspan={3}
              label="COGNOME"
              name="responsabile.cognome"
              value={values.responsabile.cognome}
              onChange={handleChange}
              error={errors.responsabile?.cognome}
            />

            <Input
              colspan={3}
              label="CODICE FISCALE"
              name="responsabile.codice_fiscale"
              value={values.responsabile.codice_fiscale}
              onChange={handleChange}
              error={errors.responsabile?.codice_fiscale}
            />

            <Input
              colspan={3}
              label="PARTITA IVA"
              name="responsabile.partita_iva"
              value={values.responsabile.partita_iva}
              onChange={handleChange}
              error={errors.responsabile?.partita_iva}
            />

            <Input
              colspan={4}
              label="RAGIONE SOCIALE"
              name="responsabile.ragione_sociale"
              value={values.responsabile.ragione_sociale}
              onChange={handleChange}
              error={errors.responsabile?.ragione_sociale}
            />

            <Input
              colspan={2}
              label="CODICE UNIVOCO"
              name="responsabile.codice_univoco"
              value={values.responsabile.codice_univoco}
              onChange={handleChange}
              error={errors.responsabile?.codice_univoco}
            />

            <TextArea
              colspan={6}
              label="NOTE CLIENTE"
              name="responsabile.annotazioni"
              value={values.responsabile.annotazioni}
              onChange={handleChange}
              error={errors.responsabile?.annotazioni}
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Indirizzo</h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Select
              label="PROV"
              options={[
                { id: 'MN', name: 'MN' },
                { id: 'BS', name: 'BS' },
                { id: 'VR', name: 'VR' },
              ]}
              colspan={1}
              name="ubicazione.provincia"
              value={values.ubicazione.provincia}
              onChange={handleChange}
              error={errors.ubicazione?.provincia}
            />

            <Input
              label="COMUNE"
              colspan={3}
              name="ubicazione.comune"
              value={values.ubicazione.comune}
              onChange={handleChange}
              error={errors.ubicazione?.comune}
            />

            <Input
              label="LOCALITA'"
              colspan={2}
              name="ubicazione.localita"
              value={values.ubicazione.localita}
              onChange={handleChange}
              error={errors.ubicazione?.localita}
            />

            <Input
              label="INDIRIZZO"
              colspan={4}
              name="ubicazione.indirizzo"
              value={values.ubicazione.indirizzo}
              onChange={handleChange}
              error={errors.ubicazione?.indirizzo}
            />

            <Input
              label="CIVICO"
              colspan={2}
              name="ubicazione.civico"
              value={values.ubicazione.civico}
              onChange={handleChange}
              error={errors.ubicazione?.civico}
            />

            <Input
              label="PIANO"
              colspan={2}
              name="ubicazione.piano"
              value={values.ubicazione.piano}
              onChange={handleChange}
              error={errors.ubicazione?.piano}
            />

            <Input
              label="INTERNO"
              colspan={2}
              name="ubicazione.interno"
              value={values.ubicazione.interno}
              onChange={handleChange}
              error={errors.ubicazione?.interno}
            />

            <Input
              label="SCALA"
              colspan={2}
              name="ubicazione.scala"
              value={values.ubicazione.scala}
              onChange={handleChange}
              error={errors.ubicazione?.scala}
            />

            <Input
              label="GOOGLE MAPS"
              colspan={6}
              name="ubicazione.maps"
              value={values.ubicazione.maps}
              onChange={handleChange}
              error={errors.ubicazione?.maps}
            />

            <TextArea
              label="ALTRE INDICAZIONI"
              name="ubicazione.annotazioni"
              value={values.ubicazione.annotazioni}
              onChange={handleChange}
              error={errors.ubicazione?.annotazioni}
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contatti</h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Input
              label="Fisso"
              colspan={6}
              name="contatti.casa"
              value={values.contatti.casa}
              onChange={handleChange}
              error={errors.contatti?.casa}
            />

            <Input
              label="Mobile"
              colspan={6}
              name="contatti.mobile"
              value={values.contatti.mobile}
              onChange={handleChange}
              error={errors.contatti?.mobile}
            />

            <Input
              label="E-mail"
              colspan={6}
              name="contatti.email"
              value={values.contatti.email}
              onChange={handleChange}
              error={errors.contatti?.email}
            />

            <TextArea
              label="Storico chiamate"
              name="contatti.chiamate"
              value={values.contatti.chiamate}
              onChange={handleChange}
              error={errors.contatti?.chiamate}
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Impianto</h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Input
              label="Targa impianto"
              colspan={6}
              name="curit.targa"
              value={values.curit.targa}
              onChange={handleChange}
              error={errors.curit?.targa}
            />

            {/* <TextArea
                label="ANNOTAZIONI"
                name="curit.annotazioni"
                value={values.curit.annotazioni}
                onChange={handleChange}
              /> */}

            <Input
              label="Modello"
              colspan={4}
              name="generatore.modello"
              value={values.generatore.modello}
              onChange={handleChange}
              error={errors.generatore?.modello}
            />

            <Input
              label="Matricola"
              colspan={2}
              name="generatore.matricola"
              value={values.generatore.matricola}
              onChange={handleChange}
              error={errors.generatore?.matricola}
            />

            <Input
              label="Collaudo"
              colspan={2}
              name="generatore.collaudo"
              value={values.generatore.collaudo}
              onChange={handleChange}
              error={errors.generatore?.collaudo}
            />

            <Input
              label="Formula comfort"
              colspan={2}
              name="generatore.formula_comfort"
              value={values.generatore.formula_comfort}
              onChange={handleChange}
              error={errors.generatore?.formula_comfort}
            />
            <Input
              label="Scadenza Garanzia"
              colspan={2}
              name="generatore.scadenza_garanzia"
              value={values.generatore.scadenza_garanzia}
              onChange={handleChange}
              error={errors.generatore?.scadenza_garanzia}
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Manutenzioni e interventi
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Input
              label="Mese controllo"
              colspan={4}
              name="manutenzioni.mese_controllo"
              value={values.manutenzioni.mese_controllo}
              onChange={handleChange}
              error={errors.manutenzioni?.mese_controllo}
            />

            <Input
              label="Prossimo controllo"
              colspan={2}
              name="manutenzioni.prossimo_controllo"
              value={values.manutenzioni.prossimo_controllo}
              onChange={handleChange}
              error={errors.manutenzioni?.prossimo_controllo}
            />

            <Input
              label="Ultimo controllo"
              colspan={3}
              name="manutenzioni.ultimo_controllo"
              value={values.manutenzioni.ultimo_controllo}
              onChange={handleChange}
              error={errors.manutenzioni?.ultimo_controllo}
            />

            <Input
              label="Ultima prova fumi"
              colspan={3}
              name="manutenzioni.ultimo_fumi"
              value={values.manutenzioni.ultimo_fumi}
              onChange={handleChange}
              error={errors.manutenzioni?.ultimo_fumi}
            />

            <TextArea
              label="Interventi"
              colspan={6}
              name="manutenzioni.interventi"
              value={values.manutenzioni.interventi}
              onChange={handleChange}
              error={errors.manutenzioni?.interventi}
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 space-y-3">
          <div className="border-b-2 border-gray">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Altre informazioni</h3>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <Input
              label="Idraulico"
              colspan={4}
              name="generatore.idraulico"
              value={values.generatore.idraulico}
              onChange={handleChange}
              error={errors.generatore?.idraulico}
            />

            <Input
              label="Di.Co"
              colspan={2}
              name="generatore.dico"
              value={values.generatore.dico}
              onChange={handleChange}
              error={errors.generatore?.dico}
            />

            <Input
              label="Causale installazione"
              name="generatore.causale_installazione"
              value={values.generatore.causale_installazione}
              onChange={handleChange}
              error={errors.generatore?.causale_installazione}
            />
            <TextArea
              label="Commenti generatore"
              colspan={6}
              name="generatore.annotazioni"
              value={values.generatore.annotazioni}
              onChange={handleChange}
              error={errors.generatore?.annotazioni}
            />
          </div>
        </div>
        {/* <div className="fixed bottom-4 right-9">
       
        </div> */}
      </form>
    </Layout>
  );
}
