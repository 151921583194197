import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../Context/AuthContext';

const Logout = () => {
  const { logout } = useAuth();

  React.useEffect(() => {
    logout();
    toast.info('Logout effettuato');
  }, []);
  return <Redirect to="/login" />;
};

export default Logout;
