import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  nome: yup.string().required('Nome richiesto'),
  cognome: yup.string().required('Cognome richiesto'),
  email: yup
    .string()
    .required('E-mail richiesta')
    .email("L'e-mail deve essere in un formato valido"),
  tipo: yup.string().required('Tipo richiesto'),
});

export const initialValues = {
  nome: '',
  cognome: '',
  email: '',
  tipo: '',
};
