import React, { useEffect, useState } from 'react';

import { subDays, addDays } from 'date-fns';

import Layout from '../../Layout';
import Select from '../../components/forms/select';
import { useAuth } from '../../Context/AuthContext';
import axios from 'axios';
import ModalRicerca from './components/ModalRicerca';
import Calendars from './components/calendar';

const Toolbar = ({ select = {}, input = {} }) => (
  <div className="w-full flex gap-6 items-end">
    <div className=" w-60">
      <Select
        label="Tecnico"
        options={select.options}
        value={select.value}
        onChange={select.onChange}
        classNameExtend="w-96"
      />
    </div>
    <ModalRicerca />
  </div>
);

const OpraiCalendar = () => {
  const { token, user } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().toUTCString()));
  const [view, setView] = useState('week');

  const [tecnico, setTecnico] = useState('');
  const [tecnici, setTecnici] = useState([]);
  const [appuntamenti, setAppuntamenti] = useState([]);

  const fetchTecnici = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/tecnici`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const pippo = data.filter(({ _id }) => _id === user._id);

    if (pippo.length === 1) setTecnico(user._id);

    setTecnici(data);
  };

  const fetchAppuntamenti = async () => {
    const params = {
      data_inizio: subDays(selectedDate, 30),
      data_fine: addDays(selectedDate, 30),
    };

    if (tecnico) {
      params.resourceId = tecnico;
    }

    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/appuntamenti`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    setAppuntamenti(data);
  };

  useEffect(() => {
    fetchTecnici();
  }, []);

  useEffect(() => {
    fetchAppuntamenti();
  }, [selectedDate, tecnico]);

  const mapped_tecnici = tecnici.map((tecnico) => {
    return { _id: tecnico._id, nome: tecnico.nome, cognome: tecnico.cognome };
  });

  const mapped_appuntamenti = appuntamenti.map((appuntamento) => {
    return {
      _id: appuntamento._id,
      title: appuntamento.title,
      startDate: new Date(appuntamento.startDate),
      resourceId: appuntamento.resourceId._id,
      notes: appuntamento.notes,
      duration: appuntamento.duration,
      type: appuntamento.type,
    };
  });

  return (
    <Layout
      title="Calendario"
      toolbar={
        <Toolbar
          select={{
            options: tecnici.map((tecnico) => ({
              id: tecnico._id,
              name: `${tecnico.nome} ${tecnico.cognome}`,
            })),
            value: tecnico,
            onChange: ({ target }) => setTecnico(target.value),
          }}
        />
      }>
      <Calendars
        user={user}
        events={mapped_appuntamenti}
        resources={mapped_tecnici}
        selectedResource={tecnico}
        view={view}
        selectedDate={selectedDate}
        onChangeView={setView}
        onChangeDate={setSelectedDate}
        refetch={fetchAppuntamenti}
      />
    </Layout>
  );
};

export default OpraiCalendar;
